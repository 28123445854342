.button__hero--demand{
    width: inherit;
    height: inherit;
    font-weight: 700;
    color: white;
    background-color: #EA2027;
    border: none;
    border-radius: 15px;
    box-shadow: 0 0 15px #101010;
}

.button__hero--demand:hover{
    cursor: pointer;
    color: #EA2027;
    background-color: white;
}
/* @media  (min-width: 768px) and (max-width: 1024px){
    .button__hero--demand{
        width: 500px;
    }
}
@media (min-width: 518px) and (max-width: 767px){
    .button__hero--demand{
        width: 500px;
    }
} */