.containerProfileDetailsEdit{
    height: 390px;
    width: 270px;
    box-shadow: 1px 1px 10px #333333;
    position: relative;
    cursor: pointer;
    grid-area: 1/1/4/3;
}
.profileDetailsEdit__imageContainer{
    width: inherit;
    height: 27%;
    overflow: hidden;
}
.profileDetailsEdit__image{
    width: inherit;
    height: 100%;
    object-fit: cover;
    object-position: center 33%;
}
.profileDetailsEdit__data{
    box-sizing: border-box;
    padding-left: 35px;
    padding-right: 35px;
    width: inherit;
    height: 70%;
    display: flex;
    flex-direction: column;
    gap: 4px;

}

.profileDetailsEdit__dataItem{
}

.profileDetailsEdit__dataHead{
    font-size: 11px;
    font-weight: 700;
}
.profileDetailsEdit__dataContent{
    color: #878787;
    font-size: 11px;
    padding-left: 10px;
}
.profileDetailsEdit__dataName{
    margin: 0;
    font-size: 11px;
    text-align: center;
    color: rgba(255, 1, 28, 1);
    padding-top: 7px;
}

.ProfileDetailsEditWrapper{
    display: flex;
    flex-direction: column;
    height: 73%;
}
.profileDetailsEdit__dataLawsuits{
    width: inherit;
    height: 25%;
    display: flex;
    flex-direction: column;
    justify-self: flex-end;
}

.profileDetailsEdit__dataLawSuitsTitle{
    font-weight: 700;
    color: rgba(225,1,28,1);
    text-align: center;
    font-size: 12px;

}
.profileDetailsEdit__dataLawSuitsIcons{
    display: flex;
    justify-content: space-around;
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
}
.profileDetailsEdit__dataLawSuitsIcon{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profileDetailsEdit__dataLawSuitsIconImageContainer{
    width: 25px;
}
.profileDetailsEdit__dataLawSuitsIconImage{
    width: 100%;
}
.profileDetailsEdit__dataLawSuitsIconData{
    font-weight: 700;
    padding-top: 5px;
    font-size: 13px;
}

.profileDetailsEdit__dataLawSuitsIconData--trueLawsuits{
    color: rgba(225,1,28,1);
}

.profileDetailsEdit__dataLawSuitsIconData--InProcess{
    color:#1747cb;
}

.profileDetailsEdit__dataLawSuitsIconData--inDebate{
    color: #cc11ba;
}

.profileDetailsEdit__dataLawSuitsIconData--falseLawsuits{
    color: #1cd848;
}


.profileDetailsEdit__button{
    position: absolute;
    top: 355px;
    left: 245px;
    text-decoration: none;
    background-color: rgba(225,1,28,1);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 5px 1px rgba(33, 33, 33, 0.6);

}
.profileDetailsEdit__buttonText{
    cursor: pointer;
    color: white;
    font-size: 10px;
    font-weight: 500;
    text-align: center;

}
.profileDetailsEdit__button:hover{
    box-shadow: 0 3px 5px 1px rgba(33, 33, 33, 0.8);
}

@media (min-width: 609px) and (max-width: 950px){
    
}
@media (min-width: 320px) and (max-width: 517px){
    .containerProfileDetailsEdit{
        margin-top: 5px;
        width: 260px;
        }
    .profileDetailsEdit__imageContainer{
        width: 260px;
    }
    .profileDetailsEdit__data{
        font-size: 12px;
        row-gap: 10px;
    }
    .profileDetailsEdit__button{
        left: 230px;
        }
}