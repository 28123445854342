.containerProfileDetails{
    grid-area: 1 / 1 / 5 / 3;
    height: 390px;
    width: 270px;
    box-shadow: 1px 1px 10px #333333;
    position: relative;
    
}
.profileDetails__imageContainer{
    width: inherit;
    height: 27%;
    overflow: hidden;
}
.profileDetails__image{
    width: inherit;
    height: 100%;
    object-fit: cover;
    object-position: center 33%;
}
.profileDetails__data{
    box-sizing: border-box;
    padding-left: 35px;
    padding-right: 35px;
    width: inherit;
    height: 50%;
    display: flex;
    flex-direction: column;
    gap: 4px;

}
.profileDetails__dataItem{

}
.profileDetails__dataHead{
    font-size: 11px;
    font-weight: 700;
}
.profileDetails__dataContent{
    color: #878787;
    font-size: 11px;
    padding-left: 10px;
}
.profileDetails__dataName{
    margin: 0;
    font-size: 11px;
    text-align: center;
    color: rgba(255, 1, 28, 1);
    padding-top: 7px;
}


.profileDetails__dataLawsuits{
    width: inherit;
    height: 25%;
    display: flex;
    flex-direction: column;
    
}

.profileDetails__dataLawSuitsTitle{
    font-weight: 700;
    color: rgba(225,1,28,1);
    text-align: center;
    font-size: 12px;
}
.profileDetails__dataLawSuitsIcons{
    display: flex;
    justify-content: space-around;
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
}
.profileDetails__dataLawSuitsIcon{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.profileDetails__dataLawSuitsIconImageContainer{
    width: 25px;
}
.profileDetails__dataLawSuitsIconImage{
    width: 100%;
}
.profileDetails__dataLawSuitsIconData{
    font-weight: 700;
    padding-top: 5px;
    font-size: 13px;
}

.profileDetails__dataLawSuitsIconData--trueLawsuits{
    color: #ea2027;
}

.profileDetails__dataLawSuitsIconData--InProcess{
    color:#1747cb;
}

.profileDetails__dataLawSuitsIconData--inDebate{
    color: #cc11ba;
}

.profileDetails__dataLawSuitsIconData--falseLawsuits{
    color: #1cd848;
}
.profileDetails__button{
    position: absolute;
    top: 355px;
    left: 245px;
    text-decoration: none;
    background-color: rgba(225,1,28,1);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 5px 1px rgba(33, 33, 33, 0.6);

}
.profileDetails__buttonText{
    cursor: pointer;
    color: white;
    font-size: 10px;
    font-weight: 500;
    text-align: center;
}
.profileDetails__button:hover{
    box-shadow: 0 3px 5px 1px rgba(33, 33, 33, 0.8);

}

@media  (min-width: 609px) and (max-width: 1024px){
    .containerProfileDetails{
        grid-area: 1 / 1 / 5 / 3;
    }
}
@media (min-width: 320px) and (max-width: 608px){
    .containerProfileDetails{
       margin-top: 25px;
    }
}
