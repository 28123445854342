.ConfirmChangePasswordContainer{
    width: 100%;
    height: 100vh;
    overflow: hidden;
    padding-top: 50px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.ConfirmChangePasswordWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.ConfirmChangePassword__header{
    color: white;
    background-color: rgba(225, 225, 225, 0.2);
    padding: 15px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.ConfirmChangePassword__errorMessage{
    font-size: 12px;
    position: absolute;
    top: 55px;
    color: rgb(255, 0, 30);
}
.ConfirmChangePassword__body{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 12px;
}
.ConfirmChangePassword__button{
    background-color: rgba(225, 1, 28, 1);
    border: none;
    color: white;
    font-weight: 600;
    cursor: pointer;
    padding: 3px 10px;
    border-radius: 3px;
}

.ConfirmChangePassword__input{
    border: none;
    border-radius: 5px;
    padding-left: 7px;
    height: 20px;
}
.ConfirmChangePasswordBackground{
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    top: 0px;
}