.ContainerRecover{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.WrapperRecover{
    padding-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 10px;
    position: relative;
}
.Recover__Whole{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 10px;
    background-color: rgba(225,225, 225, 0.5);
    padding: 7px 7px;
    border-radius: 7px;
}
.Recover__header{
    color: rgba(225,1, 28, 1);
    font-weight: 500;
    margin: 0px;
    padding-bottom: 7px;
    font-size: 16px;
}
.Recover__input{
 width: 190px;
 height: 18px;
 padding: 2px 3px;  
 border: none; 
 border-radius: 2px;
}
.Recover__button{
    cursor: pointer;
    border: none;
    border-radius: 3px;
    padding: 4px 15px;
    font-size: 13px;
    color: white;
    font-weight: 600;
    background-color: rgba(225, 1, 28, 1);
}
.ContainerRecover__successMessage{
    color: rgb(19, 196, 19);
    position: absolute;
    top: 103px;
    font-size: 12px;
}
.ContainerRecover__errorMessage{
    color: rgba(225, 1, 28, 1);
    position: absolute;
    top: 103px;
    font-size: 12px;
}
.RecoverBackground{
    position: absolute;
    object-fit: cover;
    z-index: -1;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}