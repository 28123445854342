.profilePhotoModalWrapper{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(22, 22, 22, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9000;
    overflow: hidden;
}

.profilePhotoModalContainer{
    width: 500px;
    height: 160px;
    background-color: white;
    z-index: 9999;
    overflow: hidden;
    border-radius: 2px;
}

.profilePhotoModal__header{
    background-color: rgba(234,32,39,1);
    padding: 7px;
    color: white;
    font-weight: 600;
}
.profilePhotoModal__body{
    display: flex;
    flex-direction: column;
    margin: 25px 25px auto 25px;

}
.profileDetailsModal__button{
    cursor: pointer;
    background-color: rgba(234,32,39,1);
    color: white;
    border: none;
    padding: 10px;
}

.profilePhotoModal__item{
    margin-top: 4px;
}
.profilePhotoModal__loading{
    background-color: rgba(234,32,39,1);
    width: 100%;
    height: 3px;
    font-size: 5px;
    color: white;
    animation-duration: 4s;
    animation-name: loading;
    animation-iteration-count: infinite;
}

@keyframes loading {
    from {
      transform: translate(-100%);
    }
  50%{
    transform: translate(0%);

  }
    to {
        transform: translate(100%);
    }
}

