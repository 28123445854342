.newContainer{
    padding-top: 50px;
    width: 100%;
    min-height: 100vh;
}
.new__header{
    width: inherit;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.new__image{
    margin-top: -21px;
    position: absolute;
    z-index: -1;
    width: inherit;
    height: inherit;
    object-fit:cover;
    object-position: center top;
}
.new__meta{
    width: 35%;
    height: 200px;
    background-color: rgba(255,255,255, 0.7);
    border: 3px solid red;
    padding: 35px 35px 25px 35px ;
    display: flex;
    flex-direction: column;
    justify-content:space-between;

}
.new__title{
    font-size: 30px;
    text-align: center;
}
.new__containerDetails{
    display: flex;
    justify-content: space-around;
}
.new__detail{
    color: red;
    font-weight: 600;
    font-size: 15px;
}
.new__content{
    margin-right: 405px;
    margin-left: 405px;

}
.new__contentTitle{
    font-weight: 600;
}
.new__contentParagraph{
    line-height: 25px;
    color: rgb(123, 123, 123);
}

.new__more{
    display: flex;
    justify-content: space-around;
    margin-top: 45px;
    margin-bottom: 25px;
}
@media  (min-width: 768px) and (max-width: 1024px){
    .new__content{
        margin-right: 185px;
        margin-left: 185px;
    }
}
@media (min-width: 518px) and (max-width: 767px){
    .new__content{
        margin-right: 85px;
        margin-left: 85px;
    }
    .new__title{
        font-size: 20px;
    }
    .new__detail{
        font-size: 13px;
    }
}
@media (min-width: 320px) and (max-width: 517px){
    .new__content{
        margin-right: 15px;
        margin-left: 15px;
    }
    .new__title{
        font-size: 16px;
    }
    .new__detail{
        font-size: 13px;
    }
    .new__contentTitle{
        font-size: 15px;
    }
    .new__contentParagraph{
        font-size: 14px;
    }
}