.LawsuitsListPoliticianEditContainer{
    display: flex;
    flex-direction: column;
    padding-top: 35px;
    width: 100%;
    margin-bottom: 35px;
}
.LawsuitsListPoliticianEdit__title{
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: 35px;
    background-color: #EA2027;
    color: white;
    font-weight: 600;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
}
.LawsuitsListPoliticianEdit__subTitle{
    display: grid;
    grid-template-columns: 3fr 7fr 1.3fr;
    color:#EA2027;
    font-weight: 600;
    border-bottom:#EA2027 solid 1px;
    padding-bottom: 3px;
    padding-top: 3px;
}

.LawsuitsListPoliticianEdit__item{
    font-size: 14px;
    display: grid;
    height: 20px;
    grid-template-columns: 3fr 7fr 1.3fr;
    border-bottom:rgba(233,32,39,0.1) solid 1px;
    padding-bottom: 5px;
    padding-top: 5px;
    cursor: pointer;
}
.LawsuitsListPoliticianEdit__item:hover{
    background-color: rgba(233,32,39,0.1);

}
.LawsuitsListPoliticianEdit__stateInProcess{
    color: #1747cb;
    font-weight: 600;
    cursor: pointer;
    font-size: 13px;
}
.LawsuitsListPoliticianEdit__stateInDebate{
    color: #cc11ba;
    font-weight: 600;
    cursor: pointer;
    font-size: 13px;
}
.LawsuitsListPoliticianEdit__stateFalse{
    color: #1cd848;
    font-weight: 600;
    font-size: 13px;
}
.LawsuitsListPoliticianEdit__stateTrue{
    color: #ea2027;
    font-weight: 600;
    font-size: 13px;

}
.LawsuitsListPoliticianEdit__subTitlePolitician{
padding-left: 25px;
}
.LawsuitsListPoliticianEdit__subTitleLawSuit{
padding-left: 25px;
}
.LawsuitsListPoliticianEdit__subTitleState{
}
@media  (min-width: 320px) and (max-width: 628px){
    .LawsuitsListPoliticianEditContainer{
        margin-bottom: 35px;
    }
    .LawsuitsListPoliticianEdit__title{
        font-size: 14px;
        height: 45px;
    }
    .LawsuitsListPoliticianEdit__subTitle{
        box-sizing: border-box; 
        font-size: 13px;
    }
    .LawsuitsListPoliticianEdit__lawsuit{
        font-size: 11px;   
    }
    .LawsuitsListPoliticianEdit__politician{
        font-size: 11px;
    }
    .LawsuitsListPoliticianEdit__item{
        font-size: 11px;
        grid-template-columns: 3fr 7fr 2.2fr;
    }
    .LawsuitsListPoliticianEdit__stateInProcess{
        font-size: 11px;
    }
    .LawsuitsListPoliticianEdit__stateInDebate{
        
        font-size: 11px;
    }
    .LawsuitsListPoliticianEdit__stateFalse{
        font-size: 11px;
    }
    .LawsuitsListPoliticianEdit__stateTrue{
        font-size: 11px;
    }
    .LawsuitsListPoliticianEdit__subTitle{
        grid-template-columns: 3fr 7fr 2.2fr;
    }
    .LawsuitsListPoliticianEdit__subTitlePolitician{
        padding-left: 2px;
        }
        .LawsuitsListPoliticianEdit__subTitleLawSuit{
        padding-left: 25px;
        }
        .LawsuitsListPoliticianEdit__subTitleState{
        }
}