.fileUploaderContainer{
    box-sizing: border-box;
    padding-bottom: 10px;
}
.fileUploader__body{
    margin-bottom: 10px;
}
.fileUploader__btnSearch{
    display: none;
}
.fileUploader__label{
    border: 1px solid rgba(234,32,39,1);
    color: rgba(234,32,39,1);
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}
.fileUploader__label:hover{
    background-color: rgba(234,32,39,1);
    color: white;
}
.fileUploader__labelFileDescription{
    padding-left: 5px;
    font-size: 11px;
}
.fileUploader__footer{
    display: flex;
    justify-content: center;
    margin-top: 10px;
}
.fileUploader__btnUpload{
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}
