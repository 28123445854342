.LawsuitListPoliticianContainer{
    grid-area: 13/1/17/10;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
}
.LawsuitListPoliticianWrapper{
    width: 1090px;
    display: flex;
    flex-direction: column;
    padding-top: 25px;
    box-sizing: border-box;
}
.LawsuitListPolitician__title{
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: 35px;
    background-color: #EA2027;
    color: white;
    font-weight: 600;
}
.LawsuitListPolitician__subTitle{
    display: grid;
    grid-template-columns: 3fr 7fr 1fr;
    color:#EA2027;
    font-weight: 600;
    border-bottom:#EA2027 solid 1px;
    padding-bottom: 3px;
    padding-top: 3px;
    justify-content: center;
    align-items: center;
}

.LawsuitListPolitician__item{
    font-size: 14px;
    display: grid;
    height: 20px;
    grid-template-columns: 3fr 7fr 1fr;
    border-bottom:rgba(233,32,39,0.1) solid 1px;
    padding-bottom: 5px;
    padding-top: 5px;
    cursor: pointer;
}
.LawsuitListPolitician__politician{
    display: flex;
    justify-content: center;
}
.LawsuitListPolitician__lawsuit{
    padding-left: 25px;
}
.LawsuitListPolitician__item:hover{
}
.LawsuitListPolitician__item span{
    font-size: inherit;
}
.LawsuitListPolitician__stateInProcess{
    color: #1747cb;
    font-weight: 600;
    cursor: pointer;
}
.LawsuitListPolitician__stateInDebate{
    color: #cc11ba;
    font-weight: 600;
    cursor: pointer;
}
.LawsuitListPolitician__stateFalse{
    color: #1cd848;
    font-weight: 600;
}
.LawsuitListPolitician__stateTrue{
    color: #ea2027;
    font-weight: 600;
}
.LawsuitListPolitician__state{
    display: flex;
    text-align: center;
    justify-content: center;
}
.LawsuitListPolitician__subTitlePolitician{
    display: flex;
    justify-content: center;
}
.LawsuitListPolitician__subTitleLawSuit{
padding-left: 25px;
}
.LawsuitListPolitician__subTitleState{
    display: flex;
    justify-content: center;
}
.LawsuitListPolitician__noItem{
    padding-top: 7px;

}
.LawsuitListPolician__noLawsuits{
    color: #EA2027;
    display: flex;
    justify-content: center;
}

@media  (min-width: 768px) and (max-width: 1024px){

    }
    @media (min-width: 518px) and (max-width: 767px){
    
    }
    @media (min-width: 320px) and (max-width: 517px){
    
    }