.activityWrapper{
    width: 215px;
    height: 318px;
    box-shadow: 0px 0px 7px rgba(33, 33, 33, 0.5);
    cursor: pointer;
    position: relative;
}
.activityWrapper:hover{
    box-shadow: 0px 0px 7px rgba(33, 33, 33, 0.8);

}
.activity__head{
    height: 159px;
    background-color: gray;
    position: relative;
    overflow: hidden;
}
.activity__image{
    width: inherit;
    height: inherit;
    object-fit: cover;
}
.activity__svg{
    position: absolute;
    bottom: 0px;
}
.activity__date{
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: white;
}
.activity__dateNumber{
    font-size: 26px;
    font-weight: 600;
    position: relative;
    bottom: 0px;
    top:-2px;
    right: -10px;
}
.activity__dateMonth{
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    position: relative;
    bottom: -10px;
    right: -5px;
}

.activity__body{
    box-sizing: border-box;
    width: 220px;
    height: 160px;
    padding: 20px 15px 20px 15px ;
}
.activity__bodyWrapper{
    position: relative;
    height: 100%;
    border: solid 1px rgba(33, 33, 33, 0.2);
}
.activity__title{
    position: absolute;
    top: -13px;
    left: 10px;
    padding-left: 2px;
    padding-right: 2px;
    background-color: white;
    font-size: 18px;
    font-weight: 400;
}
.activity__description{
    padding: 0px 10px 10px 10px ;
    font-weight: 400;
    font-size: 13px;
    color: rgba(33, 33, 33, 0.7);;
}
.activity__delete{
    width: 20px;
    height:30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    position: absolute;
    right:-18px;
    background-color: red;
    color: white;
}
.activity__deleteImage{
    filter: brightness(0) invert(1);
    width: 16px;
    height: 16px;
}