.heroProfileEditContainer{
    width: 570px;
    height: 390px;
    overflow: hidden;
    box-shadow: 0px 0px 10px rgba(33, 33, 33, 0.7);
    grid-area: 1/4/4/10;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
}

.heroProfileEditWrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.heroProfileEdit__imageContainer{
    width: inherit;
    height: 85%;
}

.heroProfileEdit__image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;

}


.heroProfileEdit__buttonsContainer{
    height: 15%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.heroProfileEdit__Button{
    display: flex;
    align-items: center;
    justify-content: center;
    color:white ;
    font-size: 12px;
    background-color:rgba(225,1,28,1);
    border: none;
    width: 220px;
    height: 35px;
    border-radius: 45px;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
}

@media (min-width: 628px) and (max-width: 950px){

    .heroProfileEditContainer{
        width: 80vw;
        width: 600px;
    }
    .heroProfileEdit__imageContainer{
    }
    .heroProfileEdit__Button{
        font-size: 13px;
        width: 150px;
        height: 38px;
    }
}
@media (min-width: 320px) and (max-width: 627px){
    .heroProfileEditContainer{
        width: 100%;
        height: 230px;
    }
    .heroProfileEdit__imageContainer{
        height: 190px;
    }
    .heroProfileEdit__Button{
        height: 25px;
        font-size: 11px;
        width: 125px;
    }
}