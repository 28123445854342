.LawsuitListContainer{
    display: flex;
    flex-direction: column;
    padding-top: 35px;
}
.LawsuitListContainer__title{
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: 55px;
    font-size: 18px;
    background-color: #EA2027;
    color: white;
    font-weight: 600;
}
.LawsuitListContainer__subTitle{
    display: grid;
    grid-template-columns: 3fr 7fr 1fr;
    color:#EA2027;
    font-weight: 600;
    border-bottom:#EA2027 solid 1px;
    padding-bottom: 3px;
    padding-top: 3px;
    padding-left: 45px;
    height: 30px;
    font-size: 14px;
}
.LawsuitListContainer__subTitlePolitician{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.LawsuitListContainer__subTitleLawSuit{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.LawsuitListContainer__subTitleState{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.LawsuitListContainer__item{
    display: grid;
    grid-template-columns: 3fr 7fr 1fr;
    border-bottom:rgba(233,32,39,0.1) solid 1px;
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 45px;
    cursor: pointer;
}
.LawsuitListContainer__item:hover{
    background-color: rgba(233,32,39,0.1);
}
.LawsuitListContainer__stateInProcess{
    color: #1747cb;
    font-weight: 600;
    cursor: pointer;
    font-size: 13px;
}
.LawsuitListContainer__stateInDebate{
    color: #cc11ba;
    font-weight: 600;
    cursor: pointer;
    font-size: 13px;

}
.LawsuitListContainer__stateFalse{
    color: #1cd848;
    font-weight: 600;
    font-size: 13px;
}
.LawsuitListContainer__stateTrue{
    color: #ea2027;
    font-weight: 600;
    font-size: 13px;
}