.activitiesEditContainer{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 25px;
    font-family: Arial, Helvetica, sans-serif;
}
.activitiesEditWrapper{
    width: 910px;
    display: flex;
    flex-direction: column;
    column-gap: 15px;

}
.activitiesEdit__title{
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    margin: 0px;
    color: white;
    background-color: rgba(255, 1, 28, 1);
    width: 200px;
    padding: 9px 15px;
    font-size: 14px;
    font-weight: 800;
    box-shadow: 0px -1px 3px 1px rgba(33, 33, 33, 0.3);
}
.activitiesEdit__list{
    border-top-right-radius: 2px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    padding-left: 7px;
    padding-right: 7px;
    display: flex;
    column-gap: 20px;
    align-items: center;
    padding-top: 13px;
    padding-bottom: 13px;
    box-shadow: 0px 0px 3px 3px rgba(33, 33, 33, 0.1);
}

.activitiesEdit__options{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 10px;
    width: 170px;
}

.activitiesEdit__button{
    border: none;
    color: rgba(225,1,28,1);
    font-weight: 600;
    background-color: white;
    cursor: pointer;
    width: 75px;
    height: 35px;
    border-radius: 5px;
    font-size: 14px;
}
.activitiesEdit__button:hover{
    box-shadow: 0px 0px 3px 3px rgba(33,33,33,0.1);
}
.activitiesEditModalContainer{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(33, 33, 33, 0.7);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
}

.activitiesEditModalWrapper{
    background-color: white;
    width: 300px;
    border-radius: 5px;
}
.activitiesEditModal__title{
    background-color: rgba(225,1,28,1);
    padding: 7px 10px;
    font-size: 14px;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.activitiesEditModal__body{
    padding: 7px 15px;
    display: flex;
    flex-direction: column;
    row-gap: 7px;
    border-radius: 5px;
}
.activitiesEditModal__itemTitle{
    color: rgba(225,1,28,1);
    font-size: 12px;
    font-weight: 600;
    margin: 0px;
}
.activitiesEditModal__itemDescription{
    font-family: Arial, Helvetica, sans-serif;
    border: none;
    border-bottom: 1px solid white;
    margin: 0px;
    padding: 0px;
    width: 100%;
    padding-top: 2px;
    cursor: pointer;
    height: 20px;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 600;
}
.activitiesEditModal__itemDescriptionArea{
    font-family: Arial, Helvetica, sans-serif;
    border: none;
    border-bottom: 1px solid white;
    box-sizing: border-box;
    resize: none;
    margin: 0px;
    padding: 0px;
    height: 50px;
    width: 100%;
    padding-top: 2px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 600;
    box-shadow: 0px 0px 1px 0px rgba(33, 33, 33, 0.7);
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 3px;
}
.activitiesEditModal__itemDescriptionArea::placeholder{
    color: rgba(0, 0, 0, 0.7);
    font-weight: 600;
    font-size: 13px;
    font-family: Arial, Helvetica, sans-serif;
}
.activitiesEditModal__itemDescription:focus{
    cursor: text;
    border-bottom: 1px solid rgba(0, 0, 0, 0.7);
    outline: none;
}
.activitiesEditModal__itemDescription::placeholder{
    color: rgba(0, 0, 0, 0.7);
    font-weight: 600;
}
.activitiesEditModal__dateContainer{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.activitiesEditModal__itemDescription--itemDay{
    width: 20px;
}
.activitiesEditModal__itemDescription--itemYear{
    width: 30px;
}
.activitiesEditModal__itemDescription--itemLine{
    padding-right: 3px;
}

.activitiesEditModal__itemTitle--bottom{
    cursor: pointer;
    color: rgba(225,1,28,1);
    font-size: 10px;
    padding-right: 3px;
    font-weight: 600;
    margin: 0px;
    justify-self: flex-end;
    align-self: flex-end;
}
.activitiesEditModal__button{
    background-color:rgba(225,1,28,1);
    border: none;
    color: white;
    padding-top: 7px;
    padding-bottom: 7px;
    cursor: pointer;
    border-radius: 5px;
}
.activitiesEditModal__button:hover{
    font-weight: 600;
}
.activitiesEditModal__itemImageContainer{
    position: relative;
}
.activitiesEditModal__itemIcon{
    display: none;
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 10px;
    height: 10px;
}
.activitiesEditModal__itemIcon--show{
    display: block;
}

.activitiesEditModal__itemError{
    display: none;
}
.activitiesEditModal__itemError--show{
    display: inline;
}
.activitiesEditModal__itemDescription--itemPoints{
    padding-right: 4px;
}
.activitiesEdit__exampleContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}
.activitiesEdit__exampleMessage{
    color: rgba(225,1,28,1);
    font-size: 28px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 600;

}
.activitiesEdit__exampleMessage--two{
    font-size: 20px;
}
.activitiesEdit__OneActivityContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}
.activitiesEdit__OneActivity{
    padding-left: 4px;
    padding-right: 4px;

}
.activitiesEdit__twoActivity{
    display: flex;
    column-gap: 25px;
    padding-right: 15px;
}
.activitiesEdit__options{
    padding-right: 15px;
}
.activitiesEdit__threeActivityContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 25px;
}
.activitiesEdit__threeActivity{
    display: flex;
    column-gap: 25px;
}
@media (min-width: 628px) and (max-width: 950px){

.activitiesEditWrapper{
    width: 600px;
}
.activitiesEdit__options{
    width: 110px;
}
}
@media (min-width: 320px) and (max-width: 627px){
    .activitiesEditWrapper{
        width: 320px;
    }
    .activitiesEdit__threeActivityContainer{
        padding-left: 0px;
    }
    .activitiesEditContainer{
        padding-bottom: 15px;
    }
    .activitiesEdit__options{
        width: 90px;
    }
    .activitiesEdit__exampleMessage{
        display: none;
    }
}