.heroProfileContainer{
    grid-area: 1/4/5/10;
    width: 570px;
    height: 390px;
    overflow: hidden;
    box-shadow: 0px 0px 10px rgba(33, 33, 33, 0.7);

}
.heroProfile__imageContainer{
    width: inherit;
    height: 85%;
}
.heroProfile__image{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.heroProfile__buttonsContainer{
    height: 15%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.heroProfile__Button{
    display: flex;
    align-items: center;
    justify-content: center;
    color:white ;
    font-size: 12px;
    background-color:rgba(225,1,28,1);
    border: none;
    width: 220px;
    height: 35px;
    border-radius: 45px;
    font-weight: 600;
    text-decoration: none;
}


@media  (min-width: 609px) and (max-width: 1024px){
.heroProfileContainer{
    grid-area: 5/1/10/10;
    align-self: center;
    justify-self: flex-start;
    margin-top: 30px;
    width: 600px;
}
}

@media (min-width: 320px) and (max-width: 608px){
    .heroProfileContainer{
        grid-area: 5/1/10/10;
        align-self: center;
        justify-self: flex-start;
        margin-top: -20px;
        width: 320px;
        height: 210px;
    }
    .heroProfile__Button{
        width: 100px;
        height: 20px;
        font-size: 10px;
    }
}