.ConfirmCitizenContainer{
    width: inherit;
    height: 100vh;
    background-color: red;
    padding-top: 50px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.ConfirmCitizenWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.ConfirmCitizen__header{
    color: white;
    font-weight: 600;
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
    
}
.ConfirmCitizen__button{
    text-decoration: none;
    color: white;
}
.ConfirmCitizen__button:hover{
    font-weight: 500;
}