.profileDetailsModalWrapper{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(22, 22, 22, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9000;

}

.profileDetailsModalContainer{
    width: 300px;
    height: 550px;
    background-color: white;
    z-index: 9999;
    border-radius: 5px;
}

.profileDetailsModal__header{
    background-color: rgba(234,32,39,1);
    padding: 7px;
    color: white;
    font-weight: 600;
}
.profileDetailsModal__body{
    display: flex;
    flex-direction: column;
    margin: 5px 25px auto 25px;

}
.profileDetailsModal__item{
    margin-top: 4px;
}
.profileDetailsModal__label{
    display: block;
    font-weight: 600;
    font-size: 14px;
    color: rgb(206, 0, 21);
}
.profileDetailsModal__input{
    display: block;
    width: 100%;
    border: none;
    padding: 0px;
    height: 20px;
    color: black;
    border-bottom: 1px solid white;
}
.profileDetailsModal__input:focus{
    outline: none;
    border-bottom: 1px solid black;
}
.profileDetailsModal__input::placeholder{
    color: rgba(22, 22, 22, 0.7);
}
.profileDetailsModal__inputBirthdate{
    box-sizing: border-box;
    display: flex;
    width: 101.5%;
    justify-content: left;
    align-items: center;
}
.profileDetailsModal__inputBirthdate span{
    display:inline-block;
    margin-top: -5px;
    font-size: 10px;
}
.profileDetailsModal__inputDay{
     width: 20px;
     border: none;
     outline: none;   
     border-bottom: 1px solid white;
}
.profileDetailsModal__inputDay:focus{
     border-bottom: 1px solid black;
}
.profileDetailsModal__inputMonth{
    outline: none;
    margin-left: 3px;
    width: 20px;
    border: none;
    border-bottom: 1px solid white;
}
.profileDetailsModal__inputMonth:focus{
    border-bottom: 1px solid black;
}
.profileDetailsModal__inputYear{
    outline: none;
    margin-left: 3px;
    width: 50px;
    border: none;
    border-bottom: 1px solid white;

}
.profileDetailsModal__inputYear:focus{
    border-bottom: 1px solid black;
}

.profileDetailsModal__wrapperRecomendationGroup{
    position: relative;
    font-size: 14px;
}
.profileDetailsModal__regionRecomendationGroup{
    position: absolute;
    z-index: 1000;

}
.profileDetailsModal__provinceRecomendationGroup{
    position: absolute;
    z-index: 1000;

}
.profileDetailsModal__districtRecomendationGroup{
    position: absolute;
    z-index: 1000;
}
.politicians__recomendationItem{
    width: 235px;
}
.profileDetailsModal__button{
    cursor: pointer;
    background-color: rgba(234,32,39,1);
    color: white;
    border: none;
    padding: 10px;
    width: 100px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 12px;
}
.profileDetailsModal__button:hover{
font-weight: 600;
font-size: 12px;
box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 1);
}


.profileDetailsModal__footer{
    margin-top: 5px;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    justify-items: center;

}

@media  (min-width: 609px) and (max-width: 1024px){

}

@media (min-width: 320px) and (max-width: 608px){

}