.politiciansContainer{
    padding-top: 65px;
    min-height: calc(100vh - 110px);
}
.politicians__title{
    font-size: 18px;
    width: inherit;
    padding-left: 45px;
    box-sizing: border-box;
}

.politicians__ubigeo{
    box-sizing: border-box;
    padding-left: 85px;
    display: grid;
    height: 100px;
    grid-template-columns: repeat(4,1fr);
    grid-template-rows: repeat(2,1fr);
    position: relative;
} 

.politicians__input{
    background-color: #f6f6f6;
    border: none;
    box-sizing: border-box;
    padding: 0px 7px 0px 7px;
    width: 170px;
    height: 40px;
    position: relative;
}

.politicians__recomendationGroup{
    display: none;
}
.politicians__wrapperRecomendationGroup{
    width: 150px;
    height: 1px;
    background-color: white;
    padding: 0px 7px 0px 7px;

}
.politicians__regionRecomendationGroup{
    display: none;
    top: 145px;
}
.politicians__provinceRecomendationGroup{
    display: none;
    top: 145px;
}
.politicians__districtRecomendationGroup{
    display: none;
    top: 145px;
}
.politicians__recomendationItem{
    background-color: white;
    width: 150px;
    padding: 0px 7px 0px 7px;
    border-bottom: solid 1px rgba(25,25,25,0.2);
}
.politicians__recomendationItem:hover{
    cursor: pointer;
    color: red;
}

.politicians__filter{
    box-sizing: border-box;
    margin-top: 25px;
    width: 100vw;
    padding-left: 85px;
    display: grid;
    grid-template-columns: repeat(4,1fr) 3fr;
    font-weight: 600;
}

.politicians__filterOption{
    font-size: 12px;
    cursor: pointer;
}
.politicians__filterButton{
    width: 150px;
    justify-self: center;
} 

.politicians__list{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 15px;
    justify-items: center;
    margin-top: 35px;
    margin-bottom: 35px;
}

.politicians__filterButton{
    cursor: pointer;
    box-sizing: border-box;
    background-color: #EA2027;
    color: white;
    font-weight: 600;
    border:transparent 1px solid;
    padding-top:7px;
    padding-bottom:7px;
    border-radius: 10px;
}
.politicians__filterButton:hover{
   transform: scale(1.008);
}
.politicians__messageNoPolitician{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    grid-area: 1/2/1/4;
}
.politicians__messageNoPoliticianRelevant{
    color:#EA2027 ;
    font-weight: 600;
}
.politicians__messageStrong{
    font-weight: 600;
}
@media  (min-width: 768px) and (max-width: 1024px){
.politicians__filterButton{
    height: 35px;
}
.politicians__input{
    width: 160px;
}
    
}
@media (min-width: 518px) and (max-width: 767px){
    .politicians__list{
        display: flex;
        flex-wrap: wrap;
    }
    .politicians__input{
        width: 150px;
    }
    .politicians__ubigeo{
        row-gap: 15px;
        column-gap: 25px;
        padding-left: 15px;
    }
    .politicians__filterButton{
        height: 35px;
        width: 160px;
        justify-self: left;
        grid-area: 2/1/3/2;
    }
}
@media (min-width: 320px) and (max-width: 517px){
    .politicians__list{
        display: flex;
        flex-wrap: wrap;
        padding-top: 50px;
        margin-left: 15px;
        justify-content: center;
        column-gap: 40px;
        margin-left: 0px;
    }
    .politicians__input{
        width: 150px;
    }
    .politicians__ubigeo{
        row-gap: 15px;
        column-gap: 0px;
        padding-left: 15px;
        grid-template-columns: repeat(2,1fr);
        grid-template-rows: repeat(4,1fr);
        grid-auto-flow: column;
    }
    .politicians__filterButton{
        height: 35px;
        width: 140px;
        justify-self: left;
        grid-area: 1/2;
    }

    .politicians__wrapperRecomendationGroup{
        position: absolute;
        top: 43px;
        left: 10px;
        width: 145px;
    }
    .politicians__regionRecomendationGroup{
        width: 145px;
        overflow: hidden;
    }
    .politicians__provinceRecomendationGroup{
        margin-top: 53px;
        overflow: hidden;

    }
    .politicians__districtRecomendationGroup{
        margin-top: 109px;
        overflow: hidden;
    }
}