.supportContainer{
    width: 100%;
    display: flex;
    justify-content: center;
}
.supportWrapper{
    padding-top: 45px;
    display: flex;
    width: 1090px;
    column-gap: 90px;
}
.supportStadisticsContainer{
    display: flex;
    width: 270px;
    justify-content: space-between;
}
.supportStadistics__item{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    color: rgba(225,1,28,1);
    font-weight: 500;
    justify-content: center;
    align-items: center;

}
.supportStadistics__Description{
    font-size: 13px;
}

.supportStadistics__itemTop{
    cursor: pointer;
    width: 100px;
    height: 55px;
    border-radius: 5px;
    background-color: rgba(225,1,28,1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 5px;
}
.supportStadistics__itemTop:hover{
    transform: scale(1.03);
}
.supportStadistics__icon{
    width: 25px;
    height: 25px;
}
.supportStadistics__data{
    color: white;
}
.supportPoliticsListContainer{
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 25px;
}

.supportPolitics__list{
    display: flex;
    column-gap: 25px;
}
.supportPolitics__item{
    width: 75px;
    height: 75px;
    background-color: rgba(33,33,33,0.1);
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
}
.supportPolitics__item:hover{
    box-shadow: 0px 0px 3px 1px rgba(225,1,28,1);
}
.supportPoliticsList__button{
    border: none;
    color: rgba(225,1,28,1);
    font-weight: 600;
    background-color: white;
    cursor: pointer;
    width: 75px;
    height: 35px;
    border-radius: 5px;

}
.supportPoliticsList__button:hover{
    box-shadow: 0px 0px 3px 3px rgba(33,33,33,0.1);

}
.supportPolitics__default{
    display: flex;
    flex-direction: column;
    row-gap: 9px;
}
.supportPolitics__defaultText{
    color: rgba(225,1,28,1);
    font-weight: 500;
}
.supportPolitics__defaultName{
    font-weight: 600;
    font-size: 14px;
}
.supportPolitics__defaultBottom{
    display: flex;
    column-gap: 15px;
}
.supportPolitics__defaultButton{
    color: white;
    border: none;
    border-radius: 5px;
    background-color: rgba(225,1,28,1);
    padding: 7px 10px 7px 10px;
    font-weight: 600;
    cursor:pointer;
}
.supportPolitics__defaultButtonModal{
    color: white;
    border: none;
    border-radius: 5px;
    background-color: rgba(225,1,28,1);
    padding: 7px 10px 7px 10px;
    font-weight: 600;
    cursor:pointer;
}
.support__modalContainer{
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: rgba(33,33,33,0.7);
    z-index: 1000;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.support__modalWrapper{
    width: 230px;
    height: auto;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 7px;
    border-radius: 3px;
}
.support__modalHeader{
    width: inherit;
    box-sizing: border-box;
    background-color: rgba(225,1,28,1);
    color: white;
    margin: 0px;
    font-size: 14px;
    padding: 7px;
    align-self: start;
}
.support__modalContent{
    width: inherit;
    padding-top: 7px;
    padding-bottom: 7px;
    background-color: white;
    display: flex;
    justify-content: space-around;
}
.support__addModalContainer{
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 1000;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(33,33,33,0.7);
}
.support__addModalWrapper{
    width: 250px;
    display: flex;
    overflow: hidden;
}
.support__addModalCreateAccount{
    width: inherit;
    background-color: white;
    border-radius: 5px;
    transition: 500ms ease-in-out;
}
.support__addModalHead{
    background-color: rgba(225,1,28,1);
    color: white;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 20px;
    margin: 0px;
}
.support__addModalContent{
    box-sizing: border-box;
    padding: 7px 20px;
    width: inherit;
    color: black;
    font-weight: 500;
}
.support__addModalWelcome{
    color: rgba(225,1,28,1);
    margin: 0px;
    font-weight: 600;
    font-size: 16px;
}
.support__addModalMessage{
    font-weight: 600;
    font-size: 14px;
}
.support__addModalMessage--red{
    color: rgba(225,1,28,1);
    font-size: 16px;
}
.support__addModalInputContainer{
    display: flex;
    flex-direction: column;
    row-gap: 7px;
}
.support__addModalInput{
    width: calc(250px - 40px);
    box-sizing: border-box;
    height: 30px;
    border: 1px solid rgba(33,33,33,0.3);
    padding-left: 7px;
}
.support__addModalOptions{
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
}
.support__addModalInputCheckbox{
    width: 16px;
    height: 16px;
}
.support__addModalFooter{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 7px;
    padding-top: 7px;
    padding-bottom: 10px;
}
.support__addModalButton{
    color: white;
    background-color: rgba(225,1,28,1);
    border: none;
    width: 120px;
    font-weight: 600;
    padding: 7px 15px;
    border-radius: 5px;
    cursor: pointer;
}
.support__addModalTextFooter{
    color: rgba(225,1,28,1);
    font-size: 10px;
    font-weight: 600;
    cursor: pointer;
}
.support__addModalLogin{
    width: inherit;
    background-color: white;
    border-radius: 5px;
    transition: 500ms ease-in-out;

}
@media  (min-width: 609px) and (max-width: 1024px){
    .supportPolitics__defaultName{
        font-size: 12px;
        width: 150px;
    }
}
@media (min-width: 320px) and (max-width: 608px){

    .supportPolitics__item{
        display: none;
    }
}
.supportListContainer{
    padding-top: 35px;
    padding-left: 15px;
    grid-area: 5/4/6/10;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 20px;
}
.supportList__items{
    display: flex;
    column-gap: 20px;
}

.supportPoliticsList__buttonModal{
    border: none;
    color: rgba(225,1,28,1);
    font-weight: 600;
    background-color: white;
    cursor: pointer;
    width: 75px;
    height: 35px;
    border-radius: 5px;

}
.supportPoliticsList__buttonModal:hover{
    box-shadow: 0px 0px 3px 3px rgba(33,33,33,0.1);

}
@media  (min-width: 609px) and (max-width: 1024px){
    .supportListContainer{
        grid-area: 1/4/4/10;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding-top: 65px;
        column-gap: 0px;
        padding-left: 0px;
        position: relative;

    }
    .supportPolitics__defaultButton{
        position: absolute;
        bottom: 120px;
    }
    .supportList__items{
        display: flex;
        column-gap: 20px;
    }
    .supportPolitics__default{
        font-size: 14px;
    }
    .supportPoliticsList__button{
        position: absolute;
        bottom: 115px;
    }

}

@media (min-width: 320px) and (max-width: 608px){
.supportList__items{
    column-gap: 10px;
}
.supportPolitics__default{
    width: 190px;
}
.supportPolitics__defaultText{
    font-size: 12px;
}
}