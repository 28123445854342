.carrouselContainer{
    display: flex;
    flex-direction: column;
    column-gap: 7px;
    row-gap: 7px;
}
.carrouselHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.carrousel__title{
    color: rgba(225,1,28,1);
    font-size: 12px;
    font-weight: 600;
    margin: 0px;
}
.carrousel__button{
    background-color: rgba(0, 0, 0, 0.01);
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.7);
    border: none;
    border-radius: 1px;
    color: rgba(0, 0, 0, 0.7);
    cursor: pointer;
}
.carrousel__button:hover{
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 1);
    color: rgba(0, 0, 0, 1);
}
.activitiesEditModal__itemImage{
    width: 87px;
    height: 50px;
    object-fit:cover ;
}
.activitiesEditModal__itemImage:hover{
    transform: scale(1.05);
    cursor: pointer;
}
.activitiesEditModal__itemSubTitle{
    color: rgba(0, 0, 0, 0.7);
    font-weight: 600;
    font-size: 13px;
}
.carrousel__list{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    column-gap: 3px;
    row-gap: 3px;
    flex-wrap: wrap;
    width: 270px;
    height: 109px;
}
.carrousel__title--bottom{
    cursor: pointer;
    color: rgba(225,1,28,1);
    font-size: 10px;
    padding-right: 3px;
    font-weight: 600;
    margin: 0px;
    justify-self: flex-end;
    align-self: flex-end;
}
.carrousel__listContainer{
    
    width: 285px;
    overflow: hidden;
}
.carrousel__listWrapper{
    display: flex;
    column-gap: 20px;
    transition: 1s cubic-bezier(0.215, 0.610, 0.355, 1);
}