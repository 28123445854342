.citizenProfileContainer{
    height: 100vh;
    padding-top: 75px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}
.citizenProfileWrapper{
    width: 960px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 15px;
}
.citizenProfile__top{
    display: flex;
    column-gap: 70px;
}
.citizenProfile__topLeft{
    width: 270px;
}
.citizenProfile__topTitle{
    color: rgba(225,1,28,1);
    margin: 0px;
}
.citizenProfile__topMessage{
    font-weight: 500;
}
.citizenProfile__topMessage--red{
    color: rgba(225,1,28,1);
    font-weight: 500;
    font-size: 18px;
}
.citizenProfile__topbox{
    background-color: rgba(225,1,28,1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 100px;
    border-radius: 5px;
    row-gap: 10px;
}
.citizenProfile__topInput{
    width: 130px;
    height: 25px;
    border: none;
    padding-left: 7px;
}
.citizenProfile__topButton{
    width: 130px;
    box-sizing: border-box;
    background-color: rgba(225,1,28,1);
    color: white;
    border: 1px solid rgb(255, 255, 255,0.7);
    padding: 5px 15px;
    border-radius: 5px;
    font-weight: 600;
    cursor: pointer;
}
.citizenProfile__topButton:hover{
    border: 1px solid rgb(255, 255, 255,0.9);
}
.citizenProfile__middle{
    display: flex;
    column-gap: 70px;

}
.citizenProfile__middleLeft{
    width: 270px;
    height: 100px;
    box-sizing: border-box;
    background-color: rgba(225,1,28,1);
    color: white;
    font-size: 20px;
    text-align: center;
    font-weight: 600;
    padding: 10px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.citizenProfile__middleRight{
    width: 200px;
}
.citizenProfile__middleMessage{
    color: rgba(225,1,28,1);
    margin: 0px;
}
.citizenProfile__middleSubText{
    font-weight: 600;
    font-size: 10px;
    cursor: pointer;
}
.citizenProfile__bottom{
    padding-top: 15px;
    display: flex;
    justify-content: flex-start;
    width: 540px;
    flex-direction: column;
    row-gap: 15px;
}
.citizenProfile__bottomTitle{
    margin: 0px;
    color: rgba(225,1,28,1);
}
.citizenProfile__bottomSupports{
    display: flex;
    column-gap: 15px;
}
.citizenProfile__bottomSupportItem{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 3px;
}
.citizenProfile__bottomSupportImage{
    width: 75px;
    height: 75px;
    object-fit: cover;
    border-radius: 50%;
    cursor: pointer;

}
.citizenProfile__bottomSupportDelete{
    color: rgba(225,1,28,1);
    font-size: 12px;
    font-weight: 600;
    display: block;
    cursor: pointer;
    text-align: center;
    margin: 0px;
    padding: 4px;
    border-radius: 5px;
}
.citizenProfile__bottomSupportDelete:hover{
    box-shadow: 0px 0px 2px 2px rgba(33, 33, 33, 0.3);
}

.citizenProfile__bottomNoSupports{
    font-weight: 600;
    font-size: 12px;
    background-color: rgba(225,1,28,1);
    color: white;
    padding: 7px 30px;
    border-radius: 5px;
}
.citizenProfile__topText{
    font-size: 16px;
    margin: 0px;
    color: white;
}
.citizenProfile__errorAttemps{
    color: white;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
}

@media (min-width: 609px) and (max-width:1024px){

}
@media (min-width: 320px) and (max-width:608px){
    .citizenProfile__top{
        flex-direction: column;
    }
    .citizenProfile__bottom{
        align-items: center;
        width: 270px;
    }
    .citizenProfile__bottomTitle{
        width: 100%;
    }
    .citizenProfile__bottomSupports{
    width: 100%;    
    }
}