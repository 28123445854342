.LawsuitsFormContainer{
    display: flex;
    flex-direction: column;
    width: 600px;
    box-shadow: 0px 1px 5px rgba(33,33,33,0.8);
    position:relative;

}
.LawsuitsForm__buttons{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    cursor: pointer;
}
.LawsuitsForm__button{
    box-sizing: border-box;
    background-color: white;
    box-shadow: 0px 1px 5px rgba(33,33,33,0.8);
    height: 45px;
    padding-left: 10px;
    display: flex;
    align-items: center;
}

.LawsuitsForm__button--active{
    background-color: #EA2027;
    color: white;
}
.LawsuitsForm__button:hover{
    background-color: #EA2027 ;
    color: white;
}
.LawsuitsForm__identification{
    position: absolute;
    top: 45px;
    box-sizing: border-box;
    width:600px;
    min-height: 260px;
    height: 280px;
    padding: 35px;
    display: grid;
    grid-template-rows: repeat(4,1fr);
    grid-template-columns: repeat(2,1fr);
    gap: 25px;
    background-color: #EA2027;
}
.LawsuitsForm__identificationMessage{
    color: white;
    font-size: 12px;
    grid-column: 1/3;
    justify-self: right;
    margin-top: -15px;
}
.LawsuitsForm__input{
    background-color: white;
    height: 35px;
    padding-left: 5px;
    border: none;
}
.LawsuitsForm__input::placeholder{
    padding-left: 5px;
}
.LawsuitsForm__inputAnonymouseContainer{
    display: flex;
    align-items: center;
}
.LawsuitsForm__input--checkbox{
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.LawsuitsForm__input--name{
    grid-area: 2 / 1 / 2 / 3;
}
.LawsuitsForm__input--label{
    cursor: pointer;
    color: white;
    padding-left: 10px;
}
.LawsuitsForm__details{
    display: grid;
    font-family: Arial, Helvetica, sans-serif;
    grid-template-columns: repeat(3,1fr);
    grid-template-rows: repeat(4,1fr);
    gap: 20px;
    height: 280px;
    box-sizing: border-box;
    background-color: #EA2027;
    padding: 25px;
}
.LawsuitsForm__input--title{
    grid-area: 1/1/1/4;
}
.LawsuitsForm__input--nameDetails{
    grid-area: 3/1/3/3;
}
.LawsuitsForm__input--dni{

}
.LawsuitsForm__detailsMessage{
    grid-area: 4/1/3/3;
    justify-self: right;
    align-self: center;
    margin-right: -17px;
    color: white;
    font-size: 10px;
}

.LawsuitsForm__input--area{
    grid-area: 2/1/2/4;
    height: 120px;
    resize: none;
    padding-top: 7px;
    font-family: Arial, Helvetica, sans-serif;
}
.LawsuitsForm__inputArea::placeholder{

   
}
.LawsuitsForm__proofs{
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 280px;
    top: 45px;
    padding: 25px;
    background-color:#EA2027;
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.LawsuitsForm__instruction{
    color: white;
    font-size: 13px;
}
.LawsuitsForm__demandBtn{
    width: 260px;
    height: 40px;
    position: absolute;
    top: 380px;
    right: 0px;
}
.LawsuitForm__demandBtn--form{
    width: 600px;
}

.LawsuitsForm__sectionWrapper:first-child{
    display: block;
}
.LawsuitsForm__sectionWrapper:last-child{
    display: none;
}
.LawsuitsForm__sectionWrapper:nth-child(2){
    display: none;
}

@media  (min-width: 768px) and (max-width: 1024px){
    .LawsuitsFormContainer{
        width: 500px;
    }
    .LawsuitsForm__identification{
        position: absolute;
        top: 45px;
        box-sizing: border-box;
        width: 500px;
        min-height: 260px;
        height: 280px;
        padding: 35px;
        display: grid;
        grid-template-rows: repeat(4,1fr);
        grid-template-columns: repeat(8,1fr);
        grid-gap: 25px;
        gap: 25px;
        background-color: #EA2027;

    }
    .LawsuitsForm__input--dni{
        grid-area: 1/1/1/5;
    }
    .LawsuitsForm__input--name{
        grid-area: 2/1/2/9;
    }
    .LawsuitsForm__input--phone{
        grid-area: 1/5/1/9;
    }
    .LawsuitsForm__input--recaptcha{
        grid-area: 3/1/3/5;
        transform: scale(0.9);
    }
    .LawsuitsForm__identificationMessage{
        grid-area: 4/1/4/9;
    }
    .LawsuitsForm__inputAnonymouseContainer{
        grid-area: 3/6/3/9;
    }
    .LawsuitForm__demandBtn--form{
        width: 500px;
    }
}
@media (min-width: 518px) and (max-width: 767px){
    .LawsuitsFormContainer{
        width: 500px;
        position: absolute;
        right: 25px;
    }
    .LawsuitsForm__identification{
        position: absolute;
        top: 45px;
        box-sizing: border-box;
        width: 500px;
        min-height: 260px;
        height: 280px;
        padding: 35px;
        display: grid;
        grid-template-rows: repeat(4,1fr);
        grid-template-columns: repeat(8,1fr);
        grid-gap: 25px;
        gap: 25px;
        background-color: #EA2027;

    }
    .LawsuitsForm__input--dni{
        grid-area: 1/1/1/5;
    }
    .LawsuitsForm__input--name{
        grid-area: 2/1/2/9;
    }
    .LawsuitsForm__input--phone{
        grid-area: 1/5/1/9;
    }
    .LawsuitsForm__input--recaptcha{
        grid-area: 3/1/3/5;
        transform: scale(0.9);
    }
    .LawsuitsForm__identificationMessage{
        grid-area: 4/1/4/9;
    }
    .LawsuitsForm__inputAnonymouseContainer{
        grid-area: 3/6/3/9;
    }
    .LawsuitForm__demandBtn--form{
        width: 500px;
    }
}
@media (min-width: 320px) and (max-width: 517px){
    .LawsuitsFormContainer{
        width: 318px;
    }
    .LawsuitsForm__buttons{
        font-size: 14px;
    }
    .LawsuitsForm__identification{
        width: 318px;
        display: flex;
        flex-direction: column;
        height: 320px;
        grid-gap: 10px;
        gap: 10px;
    }
    .LawsuitsForm__details{
        flex-direction: column;
        gap: 10px;
        height: 320px;
        display: flex;
    }
    .LawsuitsForm__proofs{
        height: 320px;
    }
    .LawsuitsForm__input{
        height: 35px;
        width: 240px;
    }
    .LawsuitsForm__input--dni{
        width: 240px;
    }
    .LawsuitsForm__input--name{
        width: 240px;
    }
    .LawsuitsForm__input--phone{
        width: 240px;
    }
    .LawsuitsForm__input--title {
        width: 260px;
    }
    .LawsuitsForm__input--area {
        height: 120px;
        width: 260px;
    }
    .LawsuitsForm__input--nameDetails {
        width: 260px;
    }
    .LawsuitsForm__input--dniPolitician {
        width: 260px;
    }
    .LawsuitsForm__detailsMessage {
        display: none;
    }
    .LawsuitsForm__input--recaptcha{
        transform: scale(0.82);
        width: 318px;
        margin-left: -29px;
    }
    .LawsuitsForm__identificationMessage{
        
    }
    .LawsuitsForm__inputAnonymouseContainer{
        
    }
    .LawsuitForm__demandBtn--form{
        width: 318px;
    }

}