.Footer{
    
    bottom: 0px;
    box-sizing: border-box;
    height: 45px;
    width: 100vw;
    background-color:rgba(225,1,28,1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 35px;
    padding-right: 35px;
}
.Footer__options{
    display: flex;
    gap: 13px;
}
.Footer__item{
    color: white;
    text-decoration: none;
    font-size: 12px;
}
.Footer__item:not(:last-child):after{
    content: "|";
    padding-left: 13px;
}
.Footer__copyRight{
    color: white;
    font-size: 12px;

}
.Footer__copyRight::before{
    content: "\00a9";
    padding-right: 5px;
}

@media (min-width: 518px) and (max-width: 767px){
    .Footer{
        /* padding-left: 5px;
        padding-right: 5px; */
    }
    .Footer__options{
        gap:4px;
    }
    .Footer__item{
        font-size: 12px;
    }
    .Footer__item:not(:last-child):after {
        padding-left: 4px;
    }
    .Footer__copyRight {
        font-size: 10px;        
    }
    
}
@media (min-width: 320px) and (max-width: 517px){
    .Footer{
        padding-left: 15px;
        padding-right: 15px;
    }
    .Footer__options{
        gap:4px;
    }
    .Footer__item{
        font-size: 12px;
    }
    .Footer__item:not(:last-child):after {
        padding-left: 4px;
    }
    .Footer__copyRight {
        font-size: 10px;     
        text-align: right;   
    }
}