.profile{
    width: 180px;
    height: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.profile:hover{
    cursor: pointer;
    transform: scale(1.01);
}
.profile__imgContainer{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 10px;
}
.profile__image{
    width: inherit;
    height: inherit;
    object-fit: cover;
}
.profile__name{
    width: inherit;
    font-size: 19px;
    text-align: center;
}
.profile__profession{
    text-align: center;
    font-size: 15px;
    font-weight: 600;
}
.profile__position{
    text-align: center;
    font-size: 14px;
}
.profile__wrapper{
    text-decoration: none;
    color: black;
}
.profile__wrapper:visited{
    color: black;
}
@media  (min-width: 768px) and (max-width: 1024px){
    .profile__name{
        font-size: 16px;
    }
}
@media (min-width: 518px) and (max-width: 767px){
    .profile__name{
        font-size: 14px;
    }
    .profile__profession{
        font-size: 13px;
    }
    .profile__wrapper:last-child{
        display: none;
    }
    .politicians__list .profile__wrapper:last-child{
        display: block;
    }
    
}
@media (min-width: 320px) and (max-width: 517px){
    .profile{
        width: 100px;
    }
    .profile__name{
        font-size: 12px;
    }
    .profile__profession{
        font-size: 12px;
    }
    .profile__position{
        font-size: 12px;
    }
    .profile__wrapper:nth-child(3){
        display: none;
    }
    .profile__wrapper:last-child{
        display: none;
    }
    .politicians__list .profile__wrapper:nth-child(3){
        display: block;
    }
    .politicians__list .profile__wrapper:last-child{
        display: block;
    }
    

}