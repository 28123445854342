.login{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 260px;
    height: 260px;
    background-color: #EA2027 ;
    gap: 30px;
    box-shadow: 0 0 15px #101010;
}

.login__input{
    width: 200px;
    height: 33px;
    border: none;
    box-sizing: border-box;
    padding-left: 7px;

}
.login__input:focus{
    outline: #EA2027;
}


.login__button{
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 14px;
    width: 115px;
    border: none;
    height: 30px;
    border-radius: 10px;
    background-color: white;
    color: #EA2027; 
    text-decoration: none;
 }
.login__button:hover{
    cursor: pointer;
    transform: scale(0.99);
}
.login__wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.login__error{
    color: white;
    position: absolute;
    top: -25px;
    font-size: 13px;
}
.login__options{
    padding-top: 7px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 4px;
    color: white;
}
.login__option{
    display: flex;
    color: white;
    text-decoration: none;
    font-size: 12px;
    width: 65px;
}
.login__optionMiddle{
    padding-right: 3px;
}
.login__option:hover{
    font-weight: 600;
}


 /* .login__option:first-child:after{
    content: "|";
    padding-left: 7px;
} */
@media (min-width: 320px) and (max-width: 767px){
    .login{
        gap: 25px;
        width: 230px;
        height: 200px;
    }
    .login__input{
        width: 170px;
    }
}