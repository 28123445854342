.socialnetworksEditContainer{
    padding-top: 40px;
    grid-area: 5/1/7/10;
}
.socialnetworksEditWrapper{
    display: flex;
    flex-direction: column;
}
.socialnetworksEdit__title{
    color: white;
    background-color: rgba(255, 1, 28, 1);
    width: 200px;
    padding: 9px 15px;
    font-size: 14px;
    font-weight: 500;
    box-shadow: 0px -1px 3px 1px rgba(33, 33, 33, 0.3);
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}
.socialnetworksEdit__list{
    display: flex;
    justify-content: space-around;
    box-shadow: 0px 0px 3px 3px rgba(33, 33, 33, 0.1);
    padding-top: 25px;
    padding-bottom: 25px;
    border-radius: 2px;
    border-top-left-radius: 0px;
}
.socialnetworksEdit__item{
    width: 60px;
    height: 60px;
    border: 1px solid rgba(33, 33, 33, 0.1);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.socialnetworksEdit__item:hover{
    border: 1px solid rgba(33, 33, 33, 0.3);

}
.socialnetworksEdit__icon{
    width: 35px;
    height: 35px;
}
.socialnetworksEdit__icon--twitter{
    height: 30px;
    padding-top: 3px;
}
.socialnetworksEditModalContainer{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1000;
    background-color: rgba(33, 33, 33, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.socialnetworksEditModalWrapper{
    background: white;
    width: 250px;
    border-radius: 5px;
}
.socialnetworksEditModal__title{
    background-color: red;
    color: white;
    margin: 0px;
    padding: 7px 15px;
}
.socialnetworksEditModal__content{
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 13px;
}
.socialnetworksEditModal__input{
    border: 1px solid rgba(33, 33, 33, 0.3);
    width: 200px;
    height: 23px;
    border-radius: 5px;
    padding-left: 7px;
}
.socialnetworksEditModal__button{
    background-color: red;
    border: none;
    border-radius: 5px;
    color: white;
    font-weight: 600;
    padding: 5px 15px;
    cursor: pointer;
}
@media  (min-width: 628px) and (max-width: 950px){
    .socialnetworksEditContainer{
        padding-top: 0px;
    }
    .socialnetworksEdit__list{
        padding-left: 20px;
        box-sizing: border-box;
        height: 355px;
        padding-right: 20px;
        width: 250px;
        column-gap: 35px;
        flex-wrap: wrap;
        align-items: center;
    }
    .socialnetworksEdit__title{
        width: 221px;
    }
    
}
@media (min-width: 320px) and (max-width: 627px){

    .socialnetworksEditContainer{
        width: 320px;
        padding-top: 0px;
    }
}