.optionsContainer{
    position: relative;
}
.options__button{
    cursor: pointer;
    margin-top: 5px;
    margin-left: 5px;
}
.options__listOptions{
    position: absolute;
    z-index: 2000;
    left: -40px;
    top: 31px;
}
.options__item{
    display: block;
    color: white;
    box-sizing: border-box;
    background-color:rgba(225,1,28,1);
    width: 120px;
    padding: 5px;
    border-bottom: 1px solid white;
    font-size: 14px;
    text-decoration: none;
}
.options__item:hover{
    cursor: pointer;
    font-weight: 600;
}
.options__footer{

}

@media (min-width: 320px) and (max-width: 608px){
    .options__listOptions{
        display: block;
        left: calc(-50vw - 7px);
    }
    .options__item{
        width: 100vw;
        text-align: center;
    }
}