.myStadisticsContainer{
    padding-top: 40px;
}
.myStadisticsWrapper{
    display: flex;
    flex-direction: column;
}
.myStadistics__title{
    margin: 0px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    color: white;
    background-color: rgba(255, 1, 28, 1);
    width: 200px;
    padding: 9px 15px;
    font-size: 14px;
    font-weight: 500;
    box-shadow: 0px -1px 3px 1px rgba(33, 33, 33, 0.3);
}
.myStadistics__content{
    display: flex;
    box-shadow: 0px 0px 3px 3px rgba(33, 33, 33, 0.1);
    padding-bottom: 25px;
    padding-top: 25px;
    justify-content: space-around;
    border-top-right-radius: 2px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    
}
.myStadistics__stadistics{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    row-gap: 15px;
}
.myStadistics__item{
    display: flex;
    flex-direction: column;
    row-gap: 7px;
}
.myStadistics__data{
    color: rgba(33, 33, 33, 0.8);
    font-size: 15px;
}
.myStadistics__descriptionData{
    color: rgba(255, 1, 28, 1);
    font-size: 12px;
    font-weight: 500;
    padding-right: 15px;
}
.myStadistics__supporters{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}
@media (min-width: 628px) and (max-width: 950px){
    .myStadistics__content{
        flex-direction: column;
        padding-bottom: 15px;
        padding-left: 45px;
        padding-right: 25px;
    }
    .myStadistics__supporters{
        flex-direction: row;
        justify-content: center;
        padding-top: 25px;
        column-gap: 20px;
    }
}
@media (min-width: 320px) and (max-width: 627px){
    .myStadistics__content{
        flex-direction: column;
        padding-bottom: 15px;
        padding-left: 45px;
        padding-right: 25px;
    }
    .myStadistics__supporters{
        flex-direction: row;
        justify-content: center;
        padding-top: 25px;
        column-gap: 20px;
    }
}