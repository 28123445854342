.candidates__city{
    color: #878787;
    display: inline-block;

}
.candidates__city--active{
    color: #EA2027;
    font-weight: 900;
}
.candidates__city:hover{
    color: #EA2027;
    cursor: pointer;
    transform: scale(1.05);
}