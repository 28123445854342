.SignUpContainer{
    height: 100vh;
    width: 100%;
    overflow: hidden;
    padding-top: 50px;
    box-sizing: border-box;
}
.SignUpWrapper{
    height: 100vh;
}
.SignUpSubWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 90px);
}
.SignUpBackground{
    position: absolute;
    width: inherit;
    top: 10px;
    height: calc(100vh - 50px);
    object-fit: cover;
    z-index: -1;
}
.SignUp__addModalWrapper{
    width: 250px;
    display: flex;
    overflow: hidden;
    background-color: rgba(255, 255 , 255, 0.5);
}
.SignUp__addModalCreateAccount{
    width: inherit;
    background-color: white;
    border-radius: 5px;
    transition: 500ms ease-in-out;
    background-color: rgba(255, 255 , 255, 0.1);

}
.SignUp__addModalHead{
    background-color: rgba(225,1,28,0.9);
    color: white;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 20px;
    margin: 0px;
}
.SignUp__addModalContent{
    background-color: rgba(255,255,255,0.1);
    box-sizing: border-box;
    padding: 7px 20px;
    width: inherit;
    color: black;
    font-weight: 500;
}
.SignUp__addModalWelcome{
    color: rgba(225,1,28,1);
    margin: 0px;
    font-weight: 600;
    font-size: 16px;
}
.SignUp__addModalMessage{
    font-weight: 600;
    font-size: 14px;
}
.SignUp__addModalMessage--red{
    color: rgba(225,1,28,1);
    font-size: 16px;
}
.SignUp__addModalInputContainer{
    display: flex;
    flex-direction: column;
    row-gap: 7px;
}
.SignUp__addModalInput{
    width: calc(250px - 40px);
    box-sizing: border-box;
    height: 30px;
    border: 1px solid rgba(33,33,33,0.3);
    padding-left: 7px;
}
.SignUp__addModalInputImage{
    width: 23px;
    position: absolute;
    right: 7px;
    top: 4px;
    cursor: pointer;
    opacity: 0.5;
}
.SignUp__addModalInputImage:hover{
    opacity: 1;
}
.SignUp__addModalInputWrapper{
    position: relative;
}
.SignUp__addModalInputCheckbox{
    width: 16px;
    height: 16px;
}
.SignUp__addModalOptions{
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
}
.SignUp__addModalFooter{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 7px;
    padding-top: 7px;
    padding-bottom: 10px;
}
.SignUp__addModalButton{
    color: white;
    background-color: rgba(225,1,28,1);
    border: none;
    width: 120px;
    font-weight: 600;
    padding: 7px 15px;
    border-radius: 5px;
    cursor: pointer;
}
.SignUpErrorWrapper{
    position: absolute;
    right: 15px;
    bottom: 50px;
    background-color: rgba(225,1,28,0.7);
    padding: 5px 13px;    
    border-radius: 3px;
}
.SignUpErrorWrapper__message{
    color: white;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    max-width: 100px;
}