.containerPolitician{
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding-top: 75px;
}
.politicianWrapper{
    width: 910px;
    height: auto;
}

.politicianGridContainer{
    display: grid;
    grid-template-columns: repeat(9,1fr);
    grid-template-rows: repeat(5,100px);
}
.containerPoliticianHead{
    width: inherit;
    display: flex;
    justify-content: space-between;
}
.containerSocialNetworks{
    position: fixed;
    display: flex;
    flex-direction: column;
    right: 33px;
    row-gap: 20px;
    z-index: 10000;
}
.profilePolitician__socialNetwork{
    width: 37px;
    height: 37px;
    cursor: pointer;
}
.profilePolitician__socialNetwork--twitter{
    width: 42px;
    height: 37px;
}
.profilePolitician__socialNetwork:hover{
    transform: scale(1.1);

}


@media  (min-width: 600px) and (max-width: 1024px){
    .containerPolitician{

    }
    .politicianWrapper{
        width: 600px;
        height: auto;
    }
}

@media (min-width: 320px) and (max-width: 608px){

    .politicianWrapper{
        width: 320px;
        height: auto;
    }
    .politicianGridContainer{
        display: flex;
        flex-direction: column;
        width: 320px;
        justify-content: center;
        align-items: center;
    }

}