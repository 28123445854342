.Hero{
    height: 100vh;
    width: 100vw;
    position: relative;
    overflow: hidden;
}
.Hero__imageContainer{
    height: inherit;
    width: inherit;
    position: absolute;
    z-index: -1;
    overflow: hidden;
}
.Hero__image{
    width: inherit;
    height: inherit;
    object-fit: cover;
    overflow: hidden;
}

.Hero__message{
    color: white;
    position: absolute;
    letter-spacing: 1px;
    top: 70vh;
    left: 35px;
    width: 37vw;
    font-size: 45px;
}
.Hero__messageRelevant{
    color:  #EA2027 ;
}
.Hero__login{
    position: absolute;
    top: 200px;
    left: 70vw;
}
.Hero__demandBtn{
    position:absolute;
    width: 260px;
    height: 40px;
    top: 500px;
    left: 70vw;
}
.Hero__gradient{
height: 100vh;
width: 100vw;
position: absolute;
top: 0;
background: linear-gradient(
0deg, rgba(0,0,0,0.3) 1%, rgba(0,212,255,0) 100%);
z-index: 10;
}
@media (min-width: 768px) and (max-width: 1023px){

    .Hero__demandBtn{
        left: 61vw;
    }
    
    .Hero__login{
        left: 61vw;
    }
    .Hero__message {
        font-size: 38px;
    }
}
@media (min-width: 518px) and (max-width: 767px){
    .Hero__demandBtn{
        left: calc(50vw - 115px);
        width: 230px;
        top:310px;
    }
    
    .Hero__login{
        top: 90px;
        left: calc(50vw - 115px);
        }
    .Hero__message {
        font-size: 31px;
        width: 60vw;
    }
}
    @media (min-width: 320px) and (max-width: 517px){
        .Hero__demandBtn{
            left: calc(50vw - 115px);
            width: 230px;
            top:310px;
        }
        
        .Hero__login{
            top: 90px;
            left: calc(50vw - 115px);
            }
        .Hero__message {
            font-size: 25px;
            width: 60vw;
        }
    }

