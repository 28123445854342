.PageNewsContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.PageNewsWrapper{
    position:relative;
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(3,1fr);
    row-gap: 25px;
    column-gap: 25px;
    margin-bottom: 35px;
    padding-top: 85px;
    min-height: 100vh;
}
.PageNews__button{
    width: 100px;
    border: none;
    background-color: rgba(225,1,28,1);;
    color: white;
    padding-top: 7px;
    padding-bottom: 7px;
    font-weight: 600;
    cursor: pointer;
    border-radius: 3px;
    margin-bottom: 30px;
}

@media (min-width:518px) and (max-width: 670px){
    .PageNewsWrapper{
        grid-template-columns: repeat(2,1fr);
    }
}
@media (min-width:320px) and (max-width: 517px){
    .PageNewsWrapper{
        grid-template-columns: repeat(1,1fr);
    }
}
