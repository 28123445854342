.ResidenceContainer{
    padding-top: 35px;
}

.ResidenceWrapper{
}
.Residence__title{
    margin: 0px;
    color: white;
    background-color: rgba(255, 1, 28, 1);
    width: 200px;
    padding: 9px 15px;
    font-size: 14px;
    font-weight: 500;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    box-shadow: 0px -1px 3px 1px rgb(33 33 33 / 30%);
}
.Residence__body{
    box-shadow: 0px 0px 3px 3px rgb(33 33 33 / 10%);
    padding-top: 25px;
    padding-bottom: 25px;
    display: flex;
    justify-content: space-around;
    width: 100%;
    position: relative;
}
.Residence_inputsWrapper{
    display: flex;
    column-gap: 17px;
}
.Residence__inputWrapper{
}
.Residence__input{
    height: 25px;
    width: 170px;
    box-sizing: border-box;
}
.Residence__wrapperRecomendationGroup{
    position: absolute;
    background-color: white;
    width: 170px;
}
.Residence__regionRecomendationGroup{
    display: none;
}
.Residence__provinceRecomendationGroup{
    display: none;
}
.Residence__districtRecomendationGroup{
    display: none;
}

.Residence__recomendationItem{
    font-size: 13px;
    cursor: pointer;
}
.Residence__recomendationItem:hover{
    background-color: rgba(33, 33, 33, 0.2);
}
.Residence__button{
    background-color:  rgba(255, 1, 28, 1);
    color: white;
    border: none;
    font-weight: 600;
    border-radius: 3px;
    width: 100px;
    height: 25px;
    cursor: pointer;
}
@media  (min-width: 628px) and (max-width: 950px){
.Residence__body{
    justify-content:center;
    column-gap: 7px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 15px;
}
.Residence__input{
    width: 120px;
}
}

@media (min-width: 320px) and (max-width: 627px){
    .Residence_inputsWrapper{
        flex-direction: column;
        row-gap: 7px;
    }
.Residence__body{
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
}
}