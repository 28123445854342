.InvitationModalContainer{
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(33, 33, 33, 0.3);
    z-index: 100;
}
.InvitationModalWrapper{
    background-color: white;
    width: 250px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.InvitationModal__header{
    background-color: rgba(225,1,28,1);
    color: white;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 7px;
    display: flex;
    justify-content: space-between;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}
.InvitationModal_close{
    padding-right: 7px;
    cursor: pointer;
}

.InvitationModal__body{
    padding: 7px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.InvitationModal__Message{
    font-size: 12px;
}
.InvitationModal__input{
    height: 18px;
    border-radius: 5px;
    width: 100px;
    padding-left: 5px;
}
.InvitationModal__footer{
    display: flex;
    justify-content: center;
    align-items: center;
}
.InvitationModal__button{
    margin-top: 3px;
    margin-bottom: 7px;
    background-color: rgba(225,1,28,1);
    border: none;
    padding: 5px;
    padding-left: 7px;
    padding-right: 7px;
    cursor: pointer;
    border-radius: 5px;
    color: white;
    font-weight: 600;

}
