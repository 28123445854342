.slider__container{
    width: 100vw;
    overflow: hidden;

}

.slider__wrapper{
    height: 500px;
    width: calc(3*100vw);
    display: flex;
    flex-direction: row;
    margin-top: 45px;
}
.group{
    will-change: transform;
    width: 100vw;
    display: flex;
    justify-content: space-around;
    transition: 1s;
}
.slider__controller{
    display: grid;
    grid-template-columns: 1fr repeat(3,6fr) 1fr;
    align-items: center;
}

.slider__section{
    height: 1px;
    border-top: 2px solid rgba(180, 173, 173,0.5);

}
.slider__section--active{
    border-top: 2px solid rgb(48, 48, 48);

}
.slider__button{
    color: rgba(180, 173, 173,1);
    width: 45px;
    height: 45px;
    border: rgba(180, 173, 173,0.5) solid 2px;
    font-size: 22px;
    text-align: center;
    line-height: 45px;
}
.slider__button:hover{
    cursor: pointer;
    color: rgba(56, 56, 56, 1);
    border: rgba(56, 56, 56, 0.8) solid 2px;

}
.slider__button--right{
    justify-self: flex-end;  
}

@media (min-width: 768px) and (max-width: 1023px){

.slider__wrapper{
    height: 380px;
}

.slider__controller{
    box-sizing: border-box;
    margin-left:15px;
    margin-right: 30px;
}
}
@media (min-width: 320px) and (max-width: 767px){
    .slider__wrapper{
        height: 380px;
    }
    
    .slider__controller{
        box-sizing: border-box;
        margin-left:15px;
        margin-right: 30px;
    }
}