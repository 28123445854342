.prospectsContainer{
    width: 100%;
    margin-top: 65px;
    box-shadow: 0px 0px 10px rgba(12, 12, 12, 0.5);
    position:relative;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px
}
.prospectsWrapper{
    width: inherit;
}
.prospects__buttonContainer{
    position: absolute;
    top:-35px;
    display: flex;
    height: 34px;
    justify-content: left;
    align-items: center;
}

.prospects__button{
    border-top-left-radius:2px;
    border-top-right-radius:2px;
    cursor: pointer;
    display: flex;
    padding-left: 15px;
    justify-content: left;
    align-items: center;
    height: inherit;
    width: 230px;
    font-size: 14px;
    box-sizing: border-box;
    background-color: rgba(234,32,39,0.5);
    box-shadow: 0px -3px 10px rgba(12, 12, 12, 0.3);
    color: white;
    font-weight: 600;
}
.prospects__button:nth-child(2){
margin-left: 1px;
}
.prospects__button--active{
    background-color: rgba(234,32,39,1);
}
.prospects__button:hover{
    background-color: rgba(234,32,39,1);

}
.prospects__listContainer{
    min-height: 111px;
    padding: 25px;
}
.prospects__list{
    width: inherit;
    height: inherit;
}
.prospects__listItem{
    cursor: pointer;
    background-color: rgba(230,230,230,0.1);
    font-weight: 600;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 5px;
    border:1px solid rgba(0,0,0,0.2);
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: repeat(3,1fr);
    margin-bottom: 15px;
    position: relative;
    z-index:1;
}
.prospects__listItemHeader{

    height: 35px;
    color: rgba(234,32,39,1);
}


#suscribers{
    display: none;
}

.propspects__listItemButton{
    position:absolute;
    top: -12px;
    right: -12px;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color:rgba(234,32,39,1);
    background-color: white;
    border: solid 1px rgba(234,32,39,1);
    box-sizing: border-box;
    cursor: pointer;
    display: none;
    z-index:50;
}
.propspects__listItemButton:hover{
    color: white;
    background-color: rgba(234,32,39,1);
    box-sizing: border-box;
}
.prospects__messageError{
    display: flex;
    justify-content: center;
    color: rgba(234,32,39,1);
}
@media (min-width: 320px) and (max-width: 627px){
    .prospectsContainer{
        margin-top: 45px;
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .prospects__buttonContainer{
        font-size: 12px;
        height: 40px;
        top: -40px;
        width: 100%;
    }
    .prospects__button{
        width: 150px;
    }
    .prospects__listContainer{
        box-sizing: border-box;
        width: 320px;
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 0px;
    }
    .prospects__listItemHeader{
        font-size: 13px;
    }
    .prospects__listItemDescription{
        font-size: 10px;
    }
    
}