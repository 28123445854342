.stadisticsSupportersContainer{
    padding-top: 50px;
    grid-area: 5/1/6/3;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 15px;
}
.myStadisticsWrapper{
    display: flex;
    flex-direction: column;
}
.myStadistics__title{
    margin: 0px;
    color: white;
    background-color: rgba(255, 1, 28, 1);
    width: 200px;
    padding: 9px 15px;
    font-size: 14px;
    font-weight: 500;
    box-shadow: 0px -1px 3px 1px rgba(33, 33, 33, 0.3);
}
.myStadistics__content{
    display: flex;
    box-shadow: 0px 0px 3px 3px rgba(33, 33, 33, 0.1);
    padding-bottom: 25px;
    padding-top: 25px;
    justify-content: space-around;
}
.myStadistics__stadistics{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    row-gap: 15px;
}
.myStadistics__item{
    display: flex;
    flex-direction: column;
    row-gap: 7px;
}
.stadisticsSupporters__data{
    color: white;
}
.myStadistics__descriptionData{
    color: rgba(255, 1, 28, 1);
    font-size: 12px;
    font-weight: 500;
    padding-right: 15px;
}
.myStadistics__supporters{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}
.supportStadistics__itemTop{
    cursor: pointer;
    width: 100px;
    height: 55px;
    border-radius: 5px;
    background-color: rgba(225,1,28,1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 5px;
}
.supportStadistics__itemTop:hover{
    transform: scale(1.03);
}
.supportStadistics__icon{
    width: 25px;
    height: 25px;
}

@media  (min-width: 609px) and (max-width: 1024px){
    .stadisticsSupportersContainer{
        grid-area: 3/4/4/10;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        position: relative;
    }

}
@media (min-width: 320px) and (max-width: 608px){
    .stadisticsSupportersContainer{
        padding-top: 35px;
    }
}