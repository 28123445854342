.activitiesContainer{
    padding-top: 45px;
    grid-area: 6/1/10/10;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.activitiesWrapper{
    width: 1090px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 15px;
    row-gap: 25px;
    flex-wrap: wrap;
}
@media (min-width: 320px) and (max-width: 608px){
    .activitiesContainer{
        padding-top: 25px;
    }
    .activitiesWrapper{
        justify-content: space-around;
        column-gap: 15px;
        row-gap: 25px;
        flex-wrap: wrap;
    }
}