.profilePlanModalContainer{
    width: 100%;
    height: 100%;
    position: fixed;
    top:0px;
    left:0px;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(33, 33, 33, 0.3);
}
.profilePlanModalWrapper{
    background-color: white;
    width: 270px;
    border-radius: 5px;
}
.profilePlanModal__body{
    display: flex;
}
.profilePlanModal__header{
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    background-color: rgba(255, 1, 28, 1);
    color: white;
    font-weight: 500;
    font-size: 16px;
    padding-left: 10px;
    padding-top: 4px;
    padding-bottom: 2px;
    display: flex;
    justify-content: space-between;
}
.profilePLanModal__close{
    padding-right: 5px;
    cursor: pointer;
}
.profilePlanModal__image{
    width: 145px;
    height: 145px;
    margin-left: -30px;
}
.profilePlanModal__description{
    margin-left: -30px;
    padding-right: 15px;
    font-size: 12px;
    padding-top: 15px;
    text-align: center;
}
