.profileLawsuitModalWrapper{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(22, 22, 22, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9000;
    font-family: Arial, Helvetica, sans-serif;
}

.profileLawsuitModalContainer{
    width: 500px;
    height: 400px;
    z-index: 9999;
    display: flex;
    overflow: hidden;
    background-color: #fff;
    border-radius: 5px;

}

.profileLawsuitModalContainerDescription{
    min-width: 500px;
    height: 400px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 6fr 1fr;
}
.profileLawsuitModalContainerDisclaimer{
    min-width: 500px;
    height: 400px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 6fr 1fr;
}

.profileLawsuitModal__header{
    margin: 0px;
    background-color: rgba(234,32,39,1);
    padding-left: 7px;
    color: white;
    font-weight: 600;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.profileLawsuitModal__body{
    display: flex;
    flex-direction: column;
    margin: 5px 35px 15px 25px;
}
.fileUploader__btnUpload{
    background-color: rgba(234,32,39,1);
    color: white;
    border: none;
    padding: 10px;
}

.profileLawsuitModal__item{
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
}
.profileLawsuitModal__itemHead{
    font-weight: 600;
    color: red;
    padding-bottom: 5px;
}
.profileLawsuitModal__itemBody{
    box-sizing: border-box;
    width: 100%;
    font-size: 12px;
    padding-top: 10xp;
    border: solid 1px rgba(22, 22, 22, 0.2);
    border-radius: 3px;
    height: 35px;
    padding-left: 3px;
    padding-top: 3px;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}
.profileLawsuitModal__itemBody--description{
    padding-top: 3px;
    padding-left: 3px;
    height: 100px;
    resize: none;
}
.profileLawsuitModal__itemButtom{
    width: 120px;
    height: 35px;
    border: none;
    background-color: rgba(234,32,39,1);
    color: white;
    padding: 3px 10px 3px 10px;
    cursor: pointer;
    font-weight: 600;
    border-radius: 3px;
}
.profileLawsuitModal__itemButtom2{
    width: 130px;
    height: 35px;
    border: none;
    background-color: rgba(234,32,39,1);
    color: white;
    padding: 3px 10px 3px 10px;
    cursor: pointer;
    font-weight: 600;
    border-radius: 3px;
}

.profileLawsuitModal_footer{
    box-sizing: border-box;
    display: flex;
    justify-content: right;
    align-content: center;
    padding-left: 23px;
    padding-right: 23px;
    width: 100%;
}

.profileLawsuitModal_footer--two{
    justify-content: space-between;
}
.profileLawsuitModal__loading{
    background-color: rgba(234,32,39,1);
    width: 100%;
    height: 3px;
    font-size: 5px;
    color: white;
    animation-duration: 4s;
    animation-name: loading;
    animation-iteration-count: infinite;
}
.profileLawsuitModal__itemButtom--off{
    background-color: rgb(153, 144, 144);
}

@keyframes loading {
    from {
      transform: translate(-100%);
    }
  50%{
    transform: translate(0%);

  }
    to {
        transform: translate(100%);
    }
}

@media (min-width: 609px) and (max-width: 950px){
    
}
@media (min-width: 320px) and (max-width: 608px){
    .profileLawsuitModalContainer{
        width: 250px;
    }
    .profileLawsuitModalContainerDescription{
        min-width: 250px;
    }
    .profileLawsuitModalContainerDisclaimer{
        min-width: 250px;
    }
    
}