.configProfileContainer{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    color: rgba(88, 88, 88, 0.8)
}
.configProfileWrapper{
    padding-top: 65px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 13px;
}
.configProfile__itemContainer{
    font-weight: 600;
    font-size: 15px;
    cursor: pointer;
}
.configProfile__itemContainer:hover{
    color: rgba(12, 12, 12, 1)

}
.configProfile__itemDescriptionContainer{
    display: flex;
    flex-direction: column;
    row-gap: 7px;
}
.configProfile__input{
    width: 150px;
    height: 25px;
}
.configProfile__plan{
    font-size: 13px;
    cursor: pointer;
}
.configProfile__plan:hover{
    color: rgba(12, 12, 12, 1)
}
.configProfile__button{
    width: 135px;
    height: 30px;
    border: none;
    color: white;
    background-color: rgba(225,1,28,1);
    font-weight: 600;
    cursor: pointer;
}
.configProfile__error{
    color: rgba(225,1,28,1);
    font-size: 13px;
    padding-left:7px ;
}
.configProfile__success{
    color: rgb(53, 255, 59);
    font-size: 13px;
    padding-left:7px ;
}
.configProfile__buttonContainer{
    display: flex;
    justify-content: left;
    align-items: center;
}
@media (min-width: 320px) and (max-width: 517px){
    .configProfileWrapper{
        padding-left: 15px;
    }
}