.Header{
position: fixed;
background-color: rgba(225,1,28,1);
display: flex;
color: white;
justify-content: space-between;
height: 40px;
width: 100%;
z-index: 100;
box-shadow: 0 0 15px #101010;
box-sizing: border-box;
/* overflow: hidden; */
}
.Header__home{
    padding-left: 35px;
    display: flex;
    align-items: center;
    width: 145px;
    justify-content: space-around;
}

.Header__logo{
    text-decoration: none;
    display: flex;
    align-items: center;
}
.Header__imageContainer{
    overflow: hidden;
    height: 40px;
    width: 40px;
}
.Header__image{
    width: inherit;
    height: inherit;
    object-fit: cover;
}
.Header__logoText{
    font-size: 13px;
    width: 150px;
    padding-left: 3px;
    color: white;
    display: inline-block;
}

.Header__navbar{
    height: inherit;
    width: 470px;
    padding-right: 15px;
}
.Header__navbarList{
    margin:0;
    display: flex;
    height: inherit;
    list-style: none;
    justify-content: space-around;
    align-items: center;
}
.Header__navbarItem{
    width: 90px;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;    
}
.Header__navbarItem:hover{
    cursor: pointer;
}

.Header__navbarItemLink{
    padding-left: 5px;
    font-size: 12px;
    font-weight: 500;
    display: inline-block;
    text-decoration: none;
    color: white;
    width: inherit;
    height: inherit;
    text-align: center;
    display: flex;
    align-items: center;
}

.Header__navbarItemProfile{
    margin-left: -10px;
    padding-left: 5px;
    width: inherit;
    height: inherit;
    display: flex;
    justify-content: left;
    align-items: center;
    width: 90px;
}
.Header__navbarItemProfileDescription{
    text-decoration: none;
    color: white;
    cursor: pointer;
    font-size: 14px;
}
/* .Header__navbarPhone{
    display: none;
    z-index: 20000;
} */
.Header__navbarPhoneImageContainer{
    position: absolute;
    right: 5px;
    margin-top: 10px;
    cursor: pointer;
}  
.Header__navbarPhoneImage{
    object-fit: cover;
    filter: brightness(0) invert(1);
    width: 25px;
    height: 25px;
}
.Header__navbarWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}
.Header__navbarWrapper--phone{
    position: absolute;

    background-color: rgba(23, 23, 23, 0.7);
    display: flex;
    height: 100vh;
    width: 100%;
}
.Header__navbar--phone{
    height: auto;
    width: 100%;
    margin: 0px;
    padding: 0px;
    background-color: rgba(23, 23, 23, 1);
    padding-top: 10px;
    padding-bottom: 10px;
}
.Header__navbarList--phone{
    margin: 0px;
    width: 100vw;
    flex-direction: column;
    justify-content: center;
    padding-left: 0px;
    align-items: center;
    row-gap: 10px;
}
.Header__navbarList--phone:hover{
}
.Header__navbarItem--phone{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 5px;
}
.Header__navbarItem--phone:hover{
    background-color: rgba(64, 64, 64, 0.8);
}
.Header__navbarItemLink{
    font-size: 14px;
}
.Header__navbarItemLink--phone{
    width: 100%;
    font-size: 16px;
    display: flex;
    justify-content: center;
}
.Header__navbarItemProfile--phone{
    width: 100%;
    display: flex;
    justify-content: center;
}
.Header__navbarItemProfile--phone:hover{
    background-color: rgba(64, 64, 64, 0.8);
}
.Header__navbarItemProfileDescription--phone{
    font-size: 16px;
}
/* 
@media (min-width: 320px) and (max-width: 517px){
/* .Header__navbar{
    display: none;
} 
.Header__navbarItemLink--phone{
    
}

.Header__navbarPhone{
}
.Header__navbarPhoneImageContainer{
    position: absolute;
    right: 5px;
    margin-top: 10px;
}   

.Header__navbarPhoneImage{
    display: block;
    object-fit: cover;
    filter: brightness(0) invert(1);
    width: 25px;
    height: 25px;

}
.Header__navbarListPhone{
    list-style: none;
    position: fixed;
    top: 20px;
    left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background:rgba(23, 23, 23, 0.9);
    margin-left: -40px;

}
.Header__navbarItemPhone{
    background-color: rgba(23, 23, 23, 0.9);
    display: block;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Header__navbarItemPhone:hover{
    cursor: pointer;
    background-color: rgba(23, 23, 23, 1);

}
.Header__navbarItemLink {
    justify-content: center;
    font-size: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.Header__navbarItemLink:hover{
}
.Header__navbarItemProfilePhone{
    display: flex;
    width: 100%;
    justify-content: center;
    background: rgba(23, 23, 23, 0.9);
    padding: 5px;
}
.Header__navbarItemProfileDescription{
    font-size: 20px;
    font-weight: 500;
}
.Header__navbarItemProfileDescription:hover{
    background-color: rgba(23, 23, 23, 0.9);
}
.options__button {
    padding: 0px 5px;
}
.options__button:hover{
    color: red;
}
} */