.HeroLawsuits{
    height: 100vh;
    min-height: 650px;
    width: 100vw;
    position: relative;
    overflow: hidden;
}
.HeroLawsuits__imageContainer{
    height: 100%;
    width: inherit;
    position: absolute;
    z-index: -1;
    overflow: hidden;
}
.HeroLawsuits__image{
    width: inherit;
    height: inherit;
    object-fit: cover;
    overflow: hidden;
}

.HeroLawsuits__message{
    color: white;
    position: absolute;
    letter-spacing: 1px;
    top: 70vh;
    left: 35px;
    width: 37vw;
    font-size: 45px;
}
.HeroLawsuits__messageRelevant{
    color: #EA2027;
}
.HeroLawsuits__form{
    position: absolute;
    top: 200px;
    right: 60px;
}
.HeroLawsuits__demandBtn{
    position:absolute;
    top: 500px;
    left: 70vw;
}
.HeroLawsuits__gradient{
height: 100vh;
width: 100vw;
position: absolute;
top: 0;
background: linear-gradient(
0deg, rgba(0,0,0,0.3) 1%, rgba(0,212,255,0) 100%);
z-index: 10;
}
@media  (min-width: 768px) and (max-width: 1024px){
    .HeroLawsuits__form{
        right: 40px;
    }
    .HeroLawsuits__message{
        top: 60vh;
        width: 19vw;
        font-size: 35px;
        height: 400px;
    }
}
@media (min-width: 518px) and (max-width: 767px){
    .HeroLawsuits__form{
        right: 5px;
    }
    .HeroLawsuits__message{
        top: 60vh;
        width: 5vw;
        font-size: 29px;
        height: 200px;
        left: 15px;
    }
}
@media (min-width: 320px) and (max-width: 517px){
    .HeroLawsuits__form{
        right: auto;
        left: calc(50vw - 159px);
        top: 167px;
    }
    .HeroLawsuits__message{
        top: 50px;
        width: 67vw;
        font-size: 29px;
        height: 200px;
        left: 15px;
    }
}