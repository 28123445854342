.userProfileContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding-top: 60px;
    padding-right: 185px;
    padding-left: 185px;
    min-height: 100vh;
}
.userProfileWrapper{
    width: 910px;
}
.containerUserProfileHead{
    display: grid;
    grid-template-columns: repeat(9,1fr);
    grid-template-rows: repeat(5,100px);

}
.userProfileFail{
    height: 100vh;
}

@media  (min-width: 628px) and (max-width: 950px){
    .userProfileWrapper{
        width: 600px;
    }
    .containerUserProfileHead{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        row-gap: 25px;    
    }
    
}
@media (min-width: 320px) and (max-width: 627px){
    .userProfileContainer{
        padding-left: 0px;
        padding-right: 0px;
    }
    .userProfileWrapper{
        width: 320px;
    }
    .containerUserProfileHead{
        display: flex;
        row-gap: 25px;    
        justify-content: center;
        flex-wrap: wrap-reverse;
    }
}