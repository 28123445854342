.canditatesWrapper{
    margin-top:45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 45px;
}

.candidates{
    height: 250px;
    display:flex;
    justify-content:center;
    gap: 65px;
}
.candidates__button{
    width: 220px;
    height: 45px;
    background-color:#EA2027 ;
    line-height: 45px;
    text-align: center;
    color: white;
    text-decoration: none;
}
.candidates__button:hover{
    cursor: pointer;
    transform: scale(1.01);
}
.candidates__cities{
    padding-right: 35px;
    padding-left: 35px;
    margin-top: 45px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;

}
.candidates__city{
    font-size: 14px;
}
.candidates__messageNoPolitician{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
}
.candidates__messageNoPoliticianRelevant{
    color:#EA2027 ;
    font-weight: 600;
}
.candidates__messageStrong{
    font-weight: 600;
}
@media (min-width: 768px) and (max-width: 1023px){
    .candidates__city{
        font-size: 16px;
    }
    .candidates{
        gap:0px;
    }
}
@media (min-width: 518px) and (max-width: 767px){
    .candidates{
        gap:0px;
    }
    
}
@media (min-width: 320px) and (max-width: 517px){
    .candidates__city{
        font-size: 12px;
    }
}