.news{
    width: 320px;
    height: 480px;
    background-color: #0D0D0D;
    border-radius: 3px;
}
.news:hover{
    cursor: pointer;
}
.news__wrapper{
    text-decoration: none;
    border-radius: 3px;
    overflow: hidden;
}
.news__wrapper:visited{
    color:inherit;
}
.news__imageContainer{
    height: 230px;
    width: inherit;
    background-color:#333333;
    overflow: hidden;
}
.news__image{
    width: inherit;
    height: inherit;
    object-fit: cover;
}
.news__category{
    font-size: 14px;
    color: white;
    text-align: center;
    background-color: #EA2027 ;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
}
.news__description{
    border-bottom-right-radius:5px ;
    border-bottom-left-radius:5px ;
}
.news__title{
    font-size: 16px;
    text-align: center;
    color: white;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 15px;
}
.news__date{
    font-size: 12px;
    display: block;
    text-align: center;
    color: white;
}
@media  (min-width: 700px) and (max-width: 1024px){
    .news{
        width: 220px;
        height: 360px;
    }
    .news__imageContainer{
        height: 165px;

    }
    .news__title{
        font-size: 14px;
    }
}
@media (min-width: 518px) and (max-width: 767px){
    .news{
        width: 220px;
        height: 360px;
    }
    .news__imageContainer{
        height: 165px;

    }
    .news__title{
        font-size: 14px;
    }
    .news__wrapper:last-child{
        display: none;
    }
    .PageNewsContainer{
        grid-template-columns: repeat(2,1fr) !important;
    }
    .PageNewsContainer .news__wrapper:last-child{
                display: block;
    }

}
@media (min-width: 320px) and (max-width: 517px){
    .news{
        width: 220px;
        height: 360px;
    }
    .news__imageContainer{
        height: 165px;

    }
    .news__title{
        font-size: 14px;
    }
    .news__wrapper:last-child{
        display: none;
    }
    .news__wrapper:first-child{
        display: none;    
    }

    .PageNewsContainer{
        grid-template-columns: repeat(1,1fr)!important;
    }
    .PageNewsContainer .news__wrapper:last-child{
                display: block;
    }
    .PageNewsContainer .news__wrapper:first-child{
                display: block;
    }
}