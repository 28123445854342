.ConfirmPoliticianContainer{
    width: inherit;
    height: 100vh;
    background-color: white;
    padding-top: 50px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.ConfirmPoliticianWrapper{
    padding-top:25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.ConfirmPolitician__header{
    color: white;
    font-weight: 600;
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
    
}
.ConfirmPolitician__button{
    text-decoration: none;
    color: white;
}
.ConfirmPolitician__button:hover{
    font-weight: 500;
}
.citizenProfile__middleLeft{
    border-radius: 3px;
}

@media (min-width: 320px) and (max-width: 608px ){
    .citizenProfile__topRight, .citizenProfile__topbox{
        display: flex;
        justify-content: center;
        height: 100px;
        margin-bottom: 20px;
    }
    .citizenProfile__middle{
        display: flex;
        flex-direction: column;
    }

    .citizenProfile__middleLeft{
        margin-bottom: 20px;
        border-radius: 3px;
    }
}