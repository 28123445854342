.profileFormContainer{
    grid-area: 10/1/13/10;
    width: 100%;
    display: flex;
    justify-content: center;
}
.profileFormWrapper{
    padding-top: 30px;
    width: 1090px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 1;
}
.profileForm__buttonsContainer{
    display: flex;
    gap: 1px;
    z-index: 100;
}
.profileForm__button:nth-last-child(odd){
    background-color: rgba(233,32,39,0.5);
}
.profileForm__button:nth-last-child(odd):hover{
    background-color: rgba(233,32,39,1);
}
.profileForm__button{
    cursor: pointer;
    box-shadow: -1px 1px 3px #333333;
    color: white;
    font-weight: 600;
    width: 230px;
    height: 38px;
    background-color: #EA2027;
    border: none;
}
.profileForm__content{
    z-index: 200;
    box-shadow: 0px 1px 3px #333333;
    padding-top: 20px;
    width: 100%;
    height: 230px;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: repeat(4,1fr);
    grid-gap: 15px;
    gap: 15px;
    padding-bottom: 15px;
    padding-right: 35px;
    padding-left: 35px;
    box-sizing: border-box;
}

.profileForm__input{
    height: 30px;
    background-color: #f6f6f6;
    border: none;
}
.profileForm__input--name{
    grid-area: 1 / 1 / 1 / 3 ;
}
.profileForm__input--email{

}
.profileForm__Button{
    cursor: pointer;
    width: 180px;
    height: 40px;
    background-color: #EA2027;
    border: none;
    color: white;
    font-weight: 600;
    margin-top: 10px;
    font-size: 16px;
}
.profileForm__Button--join{
    justify-self: center;
    grid-area: 3 / 2 / 3 / 3;
}
.profileForm__Button--suscribe{
    grid-area: 3 / 2 / 3 / 3;
    justify-self: center;
    display: none;
}
.profileForm__captcha{
    height: 80px;
    transform: scale(0.8);
    overflow: hidden;
}

@media  (min-width: 609px) and (max-width: 1024px){
    .profileForm__captcha{
        height: 80px;
        transform: scale(0.6);
        overflow: hidden;
    }
}
@media (min-width: 320px) and (max-width: 608px){
    .profileFormContainer{
        padding-top: 0px;
        align-items: center;
    }
    .profileForm__button{
        width: 160px;
        font-size: 11px;
    }
    .profileForm__Button--join{
        width: 160px; 
        align-self: center;
        font-size: 14px;
    }
    .profileForm__Button--suscribe{
        width: 160px; 
        align-self: center;
        font-size: 14px;
    }
    .profileForm__buttonsContainer{
        width:320px;
    }
    .profileForm__content{
        width: 320px;
        display: flex;
        flex-direction: column;
        padding: 10px;
    }
    .profileForm__captcha{
        height: 90px;
        transform: scale(0.6);
        margin-top: -18px;
    }
    
}